/**
 * This file has utils for interacting with Nova-related cookies.
 * All Nova cookies should begin with "nova_" and be restricted to Nova domains.
 */
import { ENV as nodeEnv, COOKIE_TYPES } from './consts';

const ENV = {
	development: 'development',
	staging: 'staging',
	production: 'production',
};

export const COOKIES = {
	ANONYMOUS_ID: 'anonymous_id',
	CARDSHOP_COUNTRY: 'cardshop_country',
	FULLSTORY_DEBUG: 'fullstory_debug',
	TRACKING_DEBUG: 'tracking_debug',
	UTM_INFO: 'utm_info',
	LANGUAGE: 'language',
};

const NOVA_COOKIE_TYPES = {
	[COOKIES.ANONYMOUS_ID]: COOKIE_TYPES.statistics,
	[COOKIES.CARDSHOP_COUNTRY]: COOKIE_TYPES.preferences,
	[COOKIES.FULLSTORY_DEBUG]: COOKIE_TYPES.necessary,
	[COOKIES.TRACKING_DEBUG]: COOKIE_TYPES.necessary,
	[COOKIES.UTM_INFO]: COOKIE_TYPES.marketing,
	[COOKIES.LANGUAGE]: COOKIE_TYPES.preferences,
};

// This is used to keep track of what cookies we have tried to set
const cookieCache = {};

let consentCache = {
	necessary: true,
	statistics: false,
	preferences: false,
	marketing: false,
};

const getCookie = name => {
	const regex = new RegExp(`(^| )${name}=([^;]+)`);
	const match = document.cookie.match(regex);
	return (match && match[2]) || '';
};

const setCookie = cookie => {
	document.cookie = cookie;
};

/**
 * Used to retrieve nova_ cookies.
 */
export const getNovaCookie = cookieName => {
	return getCookie(`nova_${cookieName}`);
};

/**
 * Used to set cookies, adding a nova_ to the name.
 * Respects consumers cookie consent.
 */
export const setNovaCookie = (cookieName, cookieValue) => {
	// Store the cookie locally
	cookieCache[cookieName] = cookieValue;

	// Check if user has consented to setting this cookie
	if (!consentCache[NOVA_COOKIE_TYPES[cookieName]]) {
		return;
	}

	let fullCookie = `nova_${cookieName}=${cookieValue};`;
	if (nodeEnv !== ENV.development) {
		fullCookie += ` domain=novacredit.com; SameSite=None; Secure`;
	}

	setCookie(fullCookie);
};

/**
 * Attempts to set any cookies that were previously cached.
 * Respects consumers cookie consent.
 */
export const onConsentUpdate = consents => {
	consentCache = consents;

	Object.entries(cookieCache).forEach(([cookieName, cookieValue]) => {
		setNovaCookie(cookieName, cookieValue);
	});
};
