import React, { useState, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import lottie from 'lottie-web';
import classNames from 'classnames';

import { renderLongText } from '~utils/richText';

import VideoModal from '~globals/VideoModal';
import Icon from '~globals/Icon';
import FancyBorder from '~globals/FancyBorder';
import CTA from '~globals/CTA';
import VideoCTA from '~globals/VideoCTA';
import HexagonalCTA from '~globals/HexagonalCTA';

const BACKGROUNDS = {
	blue: 'bg-blue-100',
	violet: 'bg-violet-100',
	white: 'bg-pureWhite',
	green: 'bg-emerald-50',
};

const Hero = ({
	compact,
	backgroundColor,
	buttons,
	description,
	eyebrow,
	fancyBorderType,
	headline,
	image,
	fallbackImageSafari,
	layout,
	logosUnderText,
	modalCta,
	videoInModal,
	includeTrustpilot,
}) => {
	const isMinimal = layout === 'minimal';
	const isCentered = !isMinimal && !image;
	const isLargeHeading = layout === 'large heading';

	const minimalContentClass =
		'col-start-2 lg:col-start-5 col-span-9 lg:col-span-17 pt-8 pb-10 lg:pt-5 lg:pb-6';
	const centeredContentClass = `col-start-2 lg:col-start-8 col-span-9 lg:col-span-11 pt-28 lg:pt-48 text-center ${
		compact ? 'pb-8 lg:pb-14' : 'pb-16 lg:pb-28'
	}`;
	const defaultContentClass = `col-start-2 lg:col-start-3 col-span-9 lg:col-span-10 ${
		fancyBorderType === 'home-hero' ? 'lg:py-24' : ''
	}`;

	const isLottie = image?.file?.contentType === 'application/json';
	const hasVideoModal = modalCta && videoInModal;

	// TODO: the Safari fallback image is in place because Safari seems to have low framerate when rendering animated webp.
	// To be removed when that issue is resolved.
	const isSafari =
		typeof navigator !== 'undefined' && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	const displayImage =
		isSafari && fallbackImageSafari
			? fallbackImageSafari
			: image
			? { ...image, url: image.file.url }
			: undefined;

	const [videoModalVisible, setVideoModalVisible] = useState(false);
	const handleButtonClick = () => {
		setVideoModalVisible(!videoModalVisible);
	};

	const lottieContainer = useRef(null);
	useEffect(() => {
		if (isLottie) {
			const animation = lottie.loadAnimation({
				container: lottieContainer.current,
				renderer: 'svg',
				loop: true,
				autoplay: true,
				path: image.file.url,
				rendererSettings: {
					className: 'w-full max-h-[60vh]',
					preserveAspectRatio: 'xMinYMid meet',
				},
			});

			return () => {
				animation.destroy();
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const imageEl = displayImage && (
		<img
			src={`${displayImage.url}?fm=webp`}
			alt={displayImage.description}
			width={displayImage.width}
			height={displayImage.height}
			className="row-start-1 col-start-1 w-full max-h-[45vh] object-contain object-center lg:object-left"
		/>
	);

	return (
		<section
			className={classNames(BACKGROUNDS[backgroundColor], {
				'pt-32': isMinimal,
			})}
		>
			{isMinimal && <FancyBorder type="top-1" foreground={backgroundColor} />}
			<div className="relative">
				<div
					className={classNames(
						'grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl',
						{
							'pt-32 items-center': !isMinimal && !isCentered,
							'pb-20': !isMinimal && !isCentered && !compact && !fancyBorderType,
						},
					)}
				>
					<div
						className={classNames({
							[minimalContentClass]: isMinimal,
							[centeredContentClass]: isCentered,
							[defaultContentClass]: !isMinimal && !isCentered,
						})}
					>
						{eyebrow && <p className="type-eyebrow opacity-60 mb-4">{eyebrow}</p>}
						<h1 className={isMinimal || isLargeHeading ? 'type-d1' : 'type-h2'}>{headline}</h1>
						{description && (
							<div
								className={
									isMinimal || isLargeHeading
										? 'type-body-3 font-serif mt-5 formatted-text'
										: 'type-body-2 font-serif mt-7 mb-10 formatted-text'
								}
							>
								{renderLongText(description.description)}
							</div>
						)}
						{logosUnderText && (
							<div className="mt-10 flex gap-x-6 lg:gap-x-10 items-center">
								{logosUnderText.map((logo, index) => (
									<>
										{index !== 0 && <div className="h-7 lg:h-12 w-px lg:w-0.5 bg-black" />}
										<img src={logo.url} key={index} alt="" className="w-auto h-14 lg:h-20" />
									</>
								))}
							</div>
						)}
						{buttons && (
							<div
								className={`mt-10 flex flex-wrap gap-x-10 gap-y-5 ${
									isCentered && 'justify-center'
								}`}
							>
								{buttons.map((button, index) => {
									if (button.video) {
										return <VideoCTA label={button.label} video={button.video.url} key={index} />;
									} else if (button.style) {
										return (
											<HexagonalCTA
												href={button.href}
												text={button.label}
												newTab={button.openInNewTab}
												redirectedToCustomerEventCustomerName={
													button.redirectedToCustomerEventCustomerName
												}
												key={index}
												buttonType={button.style}
											/>
										);
									} else {
										return (
											<CTA
												href={button.href}
												text={button.label}
												newTab={button.openInNewTab}
												key={index}
												redirectedToCustomerEventCustomerName={
													button.redirectedToCustomerEventCustomerName
												}
											/>
										);
									}
								})}
							</div>
						)}
					</div>
					{!isMinimal && displayImage && (
						<div
							className="col-span-full lg:col-start-14 lg:col-span-12 py-9 lg:py-20 mt-5 lg:mt-0"
							ref={lottieContainer}
							aria-hidden="true"
						>
							{!isLottie && !hasVideoModal && imageEl}
							{!isLottie && hasVideoModal && (
								<button
									className="grid items-center justify-center w-full text-center text-pureWhite"
									onClick={handleButtonClick}
								>
									{imageEl}
									<div className="row-start-1 col-start-1 w-full px-[10%]">
										<div className="flex items-center justify-center type-button gap-x-2">
											<Icon icon="play" width="16px" />
											<span className="mb-0.5">{modalCta}</span>
										</div>
									</div>
								</button>
							)}

							{/* Empty trustpilot div prevents layout shift */}
							{includeTrustpilot && (
								<div
									className="trustpilot-widget items-center justify-center w-full text-center mt-8"
									data-locale="en-US"
									data-template-id="5419b732fbfb950b10de65e5"
									data-businessunit-id="5eeaec5793ff0f0001f4bfdd"
									data-style-height="24px"
									data-style-width="100%"
									data-theme="light"
									data-tags="SelectedReview"
								>
									<div className="h-[24px] w-full mt-8"></div>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
			{isMinimal && (
				<FancyBorder
					foreground={backgroundColor}
					background={backgroundColor === 'gray' ? 'white' : undefined}
				/>
			)}
			{!isMinimal && fancyBorderType && (
				<FancyBorder
					foreground={fancyBorderType === 'home-hero' ? 'gray-50' : backgroundColor}
					background={
						backgroundColor === 'gray' || fancyBorderType === 'home-hero' ? 'white' : undefined
					}
					type={`bottom-${fancyBorderType}`}
				/>
			)}
			{hasVideoModal && (
				<VideoModal
					video={videoInModal.url}
					isVisible={videoModalVisible}
					onClose={handleButtonClick}
				/>
			)}
		</section>
	);
};

export const SectionHeroFragment = graphql`
	fragment SectionHeroFragment on ContentfulSectionHero {
		backgroundColor
		buttons {
			label
			href
			redirectedToCustomerEventCustomerName
			video {
				url
			}
			openInNewTab
			style
		}
		description {
			description
		}
		eyebrow
		fancyBorderType
		headline
		image {
			file {
				url
				contentType
			}
			width
			height
			description
		}
		fallbackImageSafari {
			url
			width
			height
			description
		}
		modalCta
		videoInModal {
			url
		}
		layout
		logosUnderText {
			url
		}
		compact
		includeTrustpilot
	}
`;

export default Hero;
