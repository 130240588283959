import React from 'react';
import { graphql } from 'gatsby';

import { useIsMobile } from '~hooks/useIsMobile';

const Partners = ({
	partnerCategory1Title,
	partnerCategory1Description,
	partnerCategory1MobileImage,
	partnerCategory1DesktopImage,
	partnerCategory2Title,
	partnerCategory2Description,
	partnerCategory2MobileImage,
	partnerCategory2DesktopImage,
}) => {
	const isMobile = useIsMobile();

	const category1Image =
		isMobile && partnerCategory1MobileImage
			? partnerCategory1MobileImage
			: partnerCategory1DesktopImage;

	const category2Image =
		isMobile && partnerCategory2MobileImage
			? partnerCategory2MobileImage
			: partnerCategory2DesktopImage;

	return (
		<section>
			<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl py-20 gap-y-16 lg:gap-y-0 overflow-hidden">
				<div className="col-start-2 lg:col-start-3 col-span-9 lg:col-span-7 lg:mb-0">
					<h3 className="type-h1 lg:type-h3">{partnerCategory1Title}</h3>
					<p className="type-body-2 font-serif mt-6">
						{partnerCategory1Description.partnerCategory1Description}
					</p>
				</div>
				<div className="relative col-start-1 col-span-11 lg:col-span-12 lg:col-start-13">
					<img src={`${category1Image?.file.url}?fm=webp`} alt={category1Image?.description} />
				</div>
				<div className="col-start-2 lg:col-start-3 col-span-9 lg:col-span-7 lg:mb-0">
					<h3 className="type-h1 lg:type-h3">{partnerCategory2Title}</h3>
					<p className="type-body-2 font-serif mt-6">
						{partnerCategory2Description.partnerCategory2Description}
					</p>
				</div>
				<div className="relative col-start-1 col-span-11 lg:col-span-12 lg:col-start-13">
					<img src={`${category2Image?.file.url}?fm=webp`} alt={category2Image?.description} />
				</div>
			</div>
		</section>
	);
};

export const SectionPartnersFragment = graphql`
	fragment SectionPartnersFragment on ContentfulSectionPartners {
		partnerCategory1Title
		partnerCategory1Description {
			partnerCategory1Description
		}
		partnerCategory1MobileImage {
			file {
				fileName
				url
			}
			description
		}
		partnerCategory1DesktopImage {
			file {
				fileName
				url
			}
			description
		}
		partnerCategory2Title
		partnerCategory2Description {
			partnerCategory2Description
		}
		partnerCategory2MobileImage {
			file {
				fileName
				url
			}
			description
		}
		partnerCategory2DesktopImage {
			file {
				fileName
				url
			}
			description
		}
	}
`;

export default Partners;
