import React from 'react';
import classNames from 'classnames';

import Icon from '~globals/Icon';

const RectangularCTA = ({ className, text, href, newTab, type, onClick }) => {
	const classes = classNames(
		'bg-black rounded type-button text-pureWhite flex justify-center items-center py-3 px-5 gap-1',
		className,
	);
	const inner = (
		<>
			<span>{text}</span>
			<Icon icon="arrow-right" width="14px" />
		</>
	);

	if (href) {
		return (
			<a
				href={href}
				target={newTab ? '_blank' : null}
				rel={newTab ? 'noreferrer noopener' : null}
				className={classes}
				onClick={onClick}
			>
				{inner}
			</a>
		);
	} else {
		return (
			<button type={type} className={classes} onClick={onClick}>
				{inner}
			</button>
		);
	}
};

export default RectangularCTA;
