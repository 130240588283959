import React, { createContext, useContext, useState } from 'react';

const ConsentContext = createContext(null);

export const useConsent = () => useContext(ConsentContext);

export const ConsentProvider = ({ children }) => {
	const [isConsentReady, setIsConsentReady] = useState(false);

	return (
		<ConsentContext.Provider
			value={{
				isConsentReady,
				setIsConsentReady,
			}}
		>
			{children}
		</ConsentContext.Provider>
	);
};

export default ConsentContext;
