import React from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';

import { renderRichText, renderLongText } from '~utils/richText';

import HexagonalCTA from '~globals/HexagonalCTA';
import Accordion from '~globals/Accordion';
import FancyBorder from '~globals/FancyBorder';

import './styles.css';

const Copy = ({
	backgroundColor,
	topBorder,
	topBorderColor,
	headline,
	description,
	subline,
	accordions,
	blocks,
	descriptionOnLeft,
	stats,
	disclaimer,
}) => {
	return (
		<section
			className={classNames('overflow-hidden', {
				'bg-pureWhite': backgroundColor === 'white',
				'bg-coolGray-50': backgroundColor === 'gray-50',
				'bg-coolGray-300': backgroundColor === 'gray-300',
				'bg-pink-50': backgroundColor === 'pink',
				'bg-blue-100': backgroundColor === 'blue',
			})}
		>
			{topBorder && (
				<FancyBorder
					type={`top-${topBorder}`}
					background={topBorderColor}
					foreground={backgroundColor}
				/>
			)}
			<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl py-16 lg:py-[120px]">
				<div className="col-start-2 lg:col-start-3 col-span-9 lg:col-span-7 mb-6 lg:mb-0">
					<h3 className="type-h2 hyphens">{headline}</h3>
					{descriptionOnLeft && (
						<div className="type-body-3 mt-5">
							{description && (
								<div className="mb-7 font-serif formatted-text">
									{renderLongText(description.description)}
								</div>
							)}
							{subline && <div className="type-body-2">{renderRichText(subline)}</div>}
						</div>
					)}
				</div>
				<div className="col-start-2 lg:col-start-11 col-span-9 lg:col-span-13 mt-14 lg:mt-0">
					{!descriptionOnLeft && (description || subline) && (
						<div className="type-body-3 mb-12">
							{description && (
								<div className="mb-7 font-serif formatted-text">
									{renderLongText(description.description)}
								</div>
							)}
							{subline && <div className="type-body-2">{renderRichText(subline)}</div>}
						</div>
					)}
					{accordions && (
						<ul className="type-body-3 font-serif">
							{accordions.map(({ title, description, button }, index) => (
								<Accordion
									title={title}
									content={description.description}
									cta={button}
									key={index}
								/>
							))}
						</ul>
					)}
					{blocks && (
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-x-1-col gap-y-20">
							{blocks.map(({ title, description, image, button }, index) => (
								<div key={index}>
									{image && <img className="h-20 mb-5" src={`${image.file.url}?fm=webp`} alt="" />}
									<h4 className="type-h4 mb-3">{title}</h4>
									<div className="type-body-2 font-serif formatted-text">
										{renderLongText(description.description)}
									</div>
									{button && (
										<HexagonalCTA
											href={button.href}
											text={button.label}
											newTab={button.openInNewTab}
											className="mt-5"
										/>
									)}
								</div>
							))}
						</div>
					)}

					{stats && (
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-x-1-col gap-y-20">
							{stats.map(({ key, value }, index) => (
								<div key={index}>
									<h4 className="stat-text-stroke font-sans text-[80px] lg:text-[80px] font-semibold leading-none text-emerald-100 tracking-tighter mb-3">
										{key}
									</h4>
									<p className="type-h5">{value}</p>
								</div>
							))}
						</div>
					)}

					{disclaimer && (
						<div className="type-body-1 font-serif opacity-40 mt-12">
							<div>{renderRichText(disclaimer)}</div>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export const SectionCopyFragment = graphql`
	fragment SectionCopyFragment on ContentfulSectionCopy {
		headline
		description {
			description
		}
		subline {
			raw
		}
		accordions {
			title
			description {
				description
			}
			button {
				label
				href
				openInNewTab
			}
		}
		descriptionOnLeft
		blocks {
			title
			description {
				description
			}
			image {
				file {
					fileName
					url
				}
			}
			button {
				href
				label
				openInNewTab
			}
		}
		stats {
			key
			value
		}
		disclaimer {
			raw
		}
		backgroundColor
		topBorder
		topBorderColor
	}
`;

export default Copy;
