import React, { useState } from 'react';

import HexagonalButton from '~globals/HexagonalButton';
import Icon from '~globals/Icon';
import VideoModal from '~globals/VideoModal';

const VideoCTA = ({ label, video, buttonType }) => {
	const [videoModalVisible, setVideoModalVisible] = useState(false);
	const handleButtonClick = () => {
		setVideoModalVisible(!videoModalVisible);
	};

	return (
		<>
			<HexagonalButton buttonType={buttonType} onClick={handleButtonClick}>
				<Icon icon="play" width="16px" />
				<span>{label}</span>
			</HexagonalButton>
			<VideoModal video={video} isVisible={videoModalVisible} onClose={handleButtonClick} />
		</>
	);
};

export default VideoCTA;
