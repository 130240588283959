import React, { useEffect } from 'react';
import Menu from '~globals/Menu';
import Footer from '~globals/Footer';
import Banner from '~globals/Banner';
import { Helmet } from 'react-helmet';
import { usePageTitle } from '~contexts/PageTitle';
import { useLanguage } from '~contexts/Language';
import CookiebotScript from '~globals/Cookiebot/CookiebotScript';
import CookiebotListener from '~globals/Cookiebot/CookiebotListener';

const Layout = ({
	children,
	parentGroup,
	navigationRegion,
	hideHeaderNav,
	hideFooter,
	bannerText,
	excludeBanner,
	noIndex,
}) => {
	const { htmlLanguage } = useLanguage();
	const pageTitle = usePageTitle();
	useEffect(() => {
		window.NovaAnalytics?.trackPage(pageTitle);
	}, [pageTitle]);

	return (
		<>
			<Helmet
				htmlAttributes={{
					lang: htmlLanguage,
				}}
			>
				{noIndex && <meta name="robots" content="noindex" />}
			</Helmet>

			{/* Cookiebot */}
			<CookiebotScript />
			<CookiebotListener />

			<div className="layout">
				<Menu
					parentGroup={parentGroup}
					navigationRegion={navigationRegion}
					hideHeaderNav={hideHeaderNav}
				/>
				<main id="main-content" className="bg-coolGray-50">
					{children}
				</main>
				{!hideFooter && <Footer parentGroup={parentGroup} navigationRegion={navigationRegion} />}
				{!excludeBanner && <Banner text={bannerText} />}
			</div>
		</>
	);
};

export default Layout;
