import React, { useEffect, useState, useRef } from 'react';
import { MOBILE_SIZE } from '~utils/consts';
import { usePageTitle } from '~contexts/PageTitle';

import FancyBorder from '~globals/FancyBorder';
import Icon from '~globals/Icon';

const TYPES = {
	CorporateBlog: {
		backgroundColor: 'bg-emerald-50',
		borderColor: 'green',
		name: 'Corporate Blog',
		url: '/corporate-blog',
	},
	Resources: {
		backgroundColor: 'bg-blue-50',
		borderColor: 'blue-50',
		name: 'Resources',
		url: '/resources',
	},
};

const ShareButton = ({ url, icon, name }) => {
	const pageTitle = usePageTitle();
	const handleClick = () => {
		window.NovaAnalytics.track('CONTENT_SHARED', {
			channel: name,
			content: 'Article',
			path: window.location.pathname,
			title: pageTitle,
		});
	};

	return (
		<li className="ml-5 lg:ml-0 lg:mb-5">
			<a target="_blank" rel="noreferrer noopener" href={url} onClick={handleClick}>
				<Icon icon={icon} width="32" className="mx-auto" />
			</a>
		</li>
	);
};

const Hero = ({ type, publishDate, title, image, summary, categories, adDisclosure }) => {
	const [encodedUrl, setEncodedUrl] = useState(null);
	const [margin, setMargin] = useState(0);
	const [containerHeight, setContainerHeight] = useState('auto');
	const shareRef = useRef(null);
	const imageRef = useRef(null);
	const categoriesRef = useRef(null);
	const containerRef = useRef(null);
	const borderBottomRef = useRef(null);

	useEffect(() => {
		setEncodedUrl(encodeURIComponent(`https://${window.location.host}${window.location.pathname}`));
	}, []);

	useEffect(() => {
		if (!imageRef.current) {
			return;
		}

		const imageCurrent = imageRef.current;
		const updateMargin = () => {
			const isMobile = window.innerWidth < MOBILE_SIZE;
			const imageHeight = imageCurrent.clientHeight;
			const shareHeight = shareRef.current.clientHeight;
			const categoriesHeight = categoriesRef.current.clientHeight;
			const containerScrollHeight = containerRef.current.scrollHeight;
			const borderBottomHeight = borderBottomRef.current.clientHeight;
			if (isMobile) {
				const newMargin = imageHeight * 0.6 + categoriesHeight + 16;

				setMargin(`${newMargin}px`);
				setContainerHeight(`${containerScrollHeight - newMargin}px`);
			} else if (imageHeight - shareHeight > borderBottomHeight) {
				const delta = imageHeight - shareHeight;

				setMargin(`${delta - borderBottomHeight + 40}px`);
				setContainerHeight(`${containerScrollHeight - delta}px`);
			} else {
				setMargin('0');
				setContainerHeight('auto');
			}
		};

		window.addEventListener('resize', updateMargin, false);
		imageCurrent.addEventListener('load', updateMargin);
		updateMargin();

		return () => {
			window.removeEventListener('resize', updateMargin);
			imageCurrent.removeEventListener('load', updateMargin);
		};
	}, []);

	return (
		<section className="pt-32 mb-10">
			<FancyBorder type="top-1" foreground={TYPES[type].borderColor} />
			<div className={TYPES[type].backgroundColor}>
				<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl">
					<div className="col-start-2 lg:col-start-5 col-span-9 lg:col-span-17">
						<div className="type-body-1 flex items-center justify-between mb-10">
							<div className="flex items-center">
								<a href="/">Home</a>
								<Icon icon="chevron-right" width="16" className="mx-2" />
								<a href={TYPES[type].url}>{TYPES[type].name}</a>
								<Icon icon="chevron-right" width="16" className="mx-2" />
								<span className="opacity-60">
									{title.length > 35 ? `${title.slice(0, 30)}...` : title}
								</span>
							</div>
							<div className="relative group">
								{adDisclosure && (
									<>
										<button>Advertiser Disclosure</button>
										<div className="hidden absolute right-0 top-full pt-2 group-hover:block group-focus-within:block">
											<div className="w-80 lg:w-96 border rounded-2xl bg-pureWhite p-6">
												<h3 className="type-h5 mb-3">Advertiser Disclosure</h3>
												<p>
													The card offers that appear on this site are from companies from which
													Nova Credit receives compensation. This compensation may impact how and
													where products appear on this site (including, for example, the order in
													which they appear). Nova Credit does not include all card companies or all
													card offers available in the marketplace.
												</p>
											</div>
										</div>
									</>
								)}
							</div>
						</div>
						<div className="type-body-1 font-serif mb-5">{publishDate}</div>
						<h1 className="type-h2">{title}</h1>
						{summary && <p className="type-body-3 font-serif mt-5">{summary}</p>}
					</div>
				</div>
				<div ref={containerRef} style={{ height: containerHeight }}>
					<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl items-start">
						<div
							ref={shareRef}
							className="col-start-2 lg:col-start-5 col-span-9 lg:col-span-1 mt-5 lg:mt-16 flex lg:block items-center"
						>
							<div className="type-eyebrow text-center lg:mb-5">Share</div>
							<ul className="flex lg:block">
								<ShareButton
									url={`https://wa.me/?text=${encodeURIComponent(
										'Check out this helpful article from Nova Credit: ',
									)}${encodedUrl}`}
									icon="whatsapp"
									name="WhatsApp"
								/>
								<ShareButton
									url={`https://www.facebook.com/dialog/share?app_id=168205158084341&href=${encodedUrl}`}
									icon="facebook"
									name="Facebook"
								/>
								<ShareButton
									url={`https://www.twitter.com/share?url=${encodedUrl}&text=${encodeURIComponent(
										'New to the U.S.? Check out this helpful article from Nova Credit: ',
									)}`}
									icon="twitter"
									name="Twitter"
								/>
								<ShareButton
									url={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}`}
									icon="linkedin"
									name="LinkedIn"
								/>
								<ShareButton
									url={`mailto:?subject=${encodeURIComponent(
										'Check out this helpful article from Nova Credit',
									)}&body=${encodedUrl}`}
									icon="mail"
									name="email"
								/>
							</ul>
						</div>
						<div className="col-start-2 lg:col-start-7 col-span-9 lg:col-span-13 mt-5 lg:mt-16 rounded-lg relative overflow-hidden">
							{image && (
								<img
									ref={imageRef}
									className="w-full"
									src={`${image.url}?fm=webp`}
									alt={image.description}
								/>
							)}
						</div>
						<div
							ref={categoriesRef}
							className="col-start-2 lg:col-start-21 col-span-9 lg:col-span-5 mt-4 lg:mt-16 flex flex-col items-start"
						>
							{categories &&
								categories.map((category, index) => (
									<a
										className="type-body-1 text-violet-700 bg-violet-50 rounded-3xl py-[6px] pb-[8px] px-5 mb-3 block"
										href={`${TYPES[type].url}?category=${category.slug}`}
										key={index}
									>
										{category.name}
									</a>
								))}
						</div>
					</div>
				</div>
			</div>
			<div ref={borderBottomRef} style={{ marginBottom: margin }}>
				<FancyBorder foreground={TYPES[type].borderColor} />
			</div>
		</section>
	);
};

export default Hero;
