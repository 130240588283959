/**
 * Utils that relate to the hello.novacredit.com application
 */

// Get either the localhost URL or the PR preview URL.
export const getDevUrl = ({ localhostPort = '' } = {}) => {
	if (typeof window === 'undefined') {
		return '';
	}

	const { hostname, port, protocol } = window.location;

	return `${protocol}//${hostname}${port && `:${localhostPort}`}`;
};

const getConsumerDevUrl = port => {
	if (typeof window === 'undefined') {
		// Assume we're local not in PR previews here. Could be a little confusing in PR previews,
		// but feel like devs are most likely to think this is an issue if seen locally. Can
		// always swap this to the bobacredit URL if there's confusion.
		return 'http://localhost:3333';
	}

	return window.location.hostname === 'localhost'
		? getDevUrl({ localhostPort: port })
		: window.location.origin.replace('.cms.', '.my.');
};

export const CONSUMER_URLS = {
	development: getConsumerDevUrl('3333'),
	staging: 'https://hello.aphrodite.novacredit.com',
	production: 'https://hello.novacredit.com',
};
