import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { getCurrentUrl } from '~utils/urls';

import PageTitleContext from '~contexts/PageTitle';
import { useLanguage } from '~contexts/Language';

import Layout from '~globals/Layout';
import PostHero from '~postSections/PostHero';
import PostContent from '~postSections/PostContent';

const PostCorporateBlog = ({ data }) => {
	const { language } = useLanguage();
	const { author, publishDate, title, metaTitle, image, summary, content, categories, slug } =
		data[language] ?? data.contentfulPostCorporateBlog;
	const { defaultMetaDescription, defaultMetaImage } = data.contentfulGlobalSettings;

	const displayTitle = `${metaTitle?.trim() || title?.trim()} | Nova Credit`;
	const displayDescription = summary || defaultMetaDescription;
	const displayImage = image ? image.url : defaultMetaImage.url;
	const url = getCurrentUrl('corporate-blog', slug);

	return (
		<PageTitleContext.Provider value={displayTitle}>
			<Layout parentGroup="business">
				<Helmet>
					<title>{displayTitle}</title>
					<meta name="name" content={displayTitle} />
					<meta name="url" content={url} />
					<meta name="description" content={displayDescription} />

					<meta property="og:title" content={displayTitle} />
					<meta property="og:site_name" content="Nova Credit" />
					<meta property="og:type" content="website" />
					<meta property="og:url" content={url} />
					<meta property="og:image" content={displayImage} />
					<meta property="og:description" content={displayDescription} />

					<meta name="twitter:title" content={displayTitle} />
					<meta name="twitter:url" content={url} />
					<meta name="twitter:card" content="summary_large_image" />
					<meta name="twitter:description" content={displayDescription} />
					<meta name="twitter:image" content={displayImage} />

					<link rel="canonical" href={url} />
				</Helmet>
				<PostHero
					type="CorporateBlog"
					publishDate={publishDate}
					title={title}
					image={image}
					summary={summary}
					categories={categories}
				/>
				{content && <PostContent author={author} content={content} />}
			</Layout>
		</PageTitleContext.Provider>
	);
};

export const Head = () => {
	return (
		<>
			<link rel="preconnect" href="https://fonts.googleapis.com" />
			<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
			<link
				href="https://fonts.googleapis.com/css2?family=Newsreader:opsz@6..72&display=swap"
				rel="stylesheet"
			></link>
		</>
	);
};

export const query = graphql`
	fragment postCorporateBlogFields on ContentfulPostCorporateBlog {
		publishDate(formatString: "MMMM Do YYYY")
		title
		metaTitle
		slug
		image {
			url
		}
		author {
			name
			photo {
				url
			}
			title
		}
		summary
		content {
			raw
			references {
				__typename
				... on ContentfulBodyWidgetDefault {
					contentful_id
					eyebrow
					headline
					body {
						raw
					}
					widgetColor
					form
					cta {
						href
						label
						openInNewTab
					}
					companyLogo {
						url
					}
				}
				... on ContentfulAsset {
					id
					url
					contentful_id
					title
				}
			}
		}
		categories {
			name
			slug
		}
	}

	query PostCorporateBlogQuery($contentful_id: String) {
		en: contentfulPostCorporateBlog(
			contentful_id: { eq: $contentful_id }
			node_locale: { eq: "en-US" }
		) {
			...postCorporateBlogFields
		}
		fr: contentfulPostCorporateBlog(
			contentful_id: { eq: $contentful_id }
			node_locale: { eq: "fr-CA" }
		) {
			...postCorporateBlogFields
		}
		contentfulGlobalSettings {
			defaultMetaDescription
			defaultMetaImage {
				url
			}
		}
	}
`;

export default PostCorporateBlog;
