import React, { useEffect, useRef } from 'react';

import { COOKIEBOT_ID_CAN, COOKIEBOT_ID_CCPA, COOKIEBOT_URLS } from '~utils/consts';
import { useLanguage } from '~contexts/Language';

/**
 * Embedded via a `Body Widget > Default` Contentful record
 * when the `form` field is set to `cookiebot`. Used on our
 * Cookie Policy legal copy pages.
 */
const CookiebotEmbed = () => {
	const instance = useRef(null);
	const { language } = useLanguage();

	useEffect(() => {
		instance.current.innerHTML = '';
		const cookieBotScript = document.createElement('script');
		cookieBotScript.src = COOKIEBOT_URLS.DECLARATION;
		cookieBotScript.id = 'CookieDeclaration';
		cookieBotScript.setAttribute(
			'data-georegions',
			`{'region':'US-06','cbid':'${COOKIEBOT_ID_CCPA}'},{'region':'CA','cbid':'${COOKIEBOT_ID_CAN}'}`,
		);
		cookieBotScript.setAttribute('data-culture', language);
		cookieBotScript.setAttribute('data-framework', 'TCFv2.2');
		instance.current.appendChild(cookieBotScript);
	}, [language]);

	return <div ref={instance} />;
};

export default CookiebotEmbed;
