import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ENV } from '~utils/consts';
import { CONSUMER_URLS } from '~utils/hello';

const consumerBaseURL = CONSUMER_URLS[ENV];

/**
 * Redirect traffic from:
 *     novacredit.com/r/some-referral-code
 * to:
 *     hello.novacredit.com/cards?referrer=[some-referral-code]
 *
 * We do this (rather than generating links directly to the hello app) for a couple of reasons:
 *  - keeps the referral links short by tying novacredit.com rather than a subdomain
 *  - the CMS supports SSR, so the link previews will look nice (packages/consumer-dashboard has no SSR support)
 *  - for historical reasons, since old referral links were tied to the novacredit.com domain
 */
const Refer = ({ params }) => {
	useEffect(() => {
		const redirectToURL = `${consumerBaseURL}/cards?referrer=${params.referralCode}`;
		window.location.replace(redirectToURL);
	}, [params.referralCode]);

	const displayTitle = 'Nova Credit';
	const displayDescription =
		"Get $10 when you use your foreign credit history to apply for one of our partner’s credit cards. It's that simple.";

	return (
		<Helmet>
			<title>{displayTitle}</title>
			<meta name="name" content={displayTitle} />
			<meta name="description" content={displayDescription} />

			<meta property="og:title" content={displayTitle} />
			<meta property="og:site_name" content="Nova Credit" />
			<meta property="og:type" content="website" />
			<meta property="og:description" content={displayDescription} />

			<meta name="twitter:title" content={displayTitle} />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:description" content={displayDescription} />
		</Helmet>
	);
};

export default Refer;
