import React from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';

import EligibilityForm from '~globals/EligibilityForm';
import FancyBorder from '~globals/FancyBorder';

import map from './map.png';

const CheckEligibility = ({
	headline,
	description,
	dropdownPlaceholder,
	ctaLabel,
	dontSeeYourCountryLabel,
	sectionId,
	backgroundColor,
}) => {
	return (
		<section
			id={sectionId}
			className={classNames({
				'bg-pink-50': backgroundColor === 'pink',
				'bg-coolGray-50': backgroundColor === 'gray',
			})}
		>
			<div className="grid grid-cols-1 items-center">
				<img src={map} alt="" className="-mb-2-col lg:mb-0 row-start-1 col-span-full" />
				<div className="row-start-2 lg:row-start-1 col-span-full grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl py-10">
					<div className="col-span-full lg:col-span-13 lg:col-start-7 py-10 px-8 lg:px-20 border border-black rounded-lg bg-pureWhite">
						<h4 className="type-h3 mb-4 text-center">{headline}</h4>
						<div className="type-body-3 font-serif formatted-text mb-10 text-center">
							{description.description}
						</div>
						<EligibilityForm
							dropdownPlaceholder={dropdownPlaceholder}
							ctaLabel={ctaLabel}
							dontSeeYourCountryLabel={dontSeeYourCountryLabel}
							isStacked={true}
						/>
					</div>
				</div>
			</div>
			<FancyBorder type="bottom-3" background="white" foreground={backgroundColor} />
		</section>
	);
};

export const SectionCheckEligibilityFragment = graphql`
	fragment SectionCheckEligibilityFragment on ContentfulSectionCheckEligibility {
		ctaLabel
		description {
			description
		}
		headline
		dropdownPlaceholder
		dontSeeYourCountryLabel
		sectionId
		backgroundColor
	}
`;

export default CheckEligibility;
