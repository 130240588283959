import React from 'react';
import { graphql } from 'gatsby';
import Icon from '~globals/Icon';

const TextImageFullscreen = ({ headline, description, logos, cta, image }) => {
	return (
		<section>
			<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl py-20">
				<h3 className="type-h3 col-span-9 lg:col-span-11 col-start-2 lg:col-start-8 text-center">
					{headline}
				</h3>
				<p className="type-body-3 font-serif col-span-9 lg:col-span-11 col-start-2 lg:col-start-8 text-center mt-5">
					{description.description}
				</p>
				{logos && (
					<div className="flex flex-wrap lg:flex-nowrap items-center col-span-9 lg:col-span-13 col-start-2 lg:col-start-7 justify-between mt-5 gap-2">
						{logos.map((logo, index) => (
							<div className="basis-2/5 lg:basis-1/5" key={index}>
								<img src={`${logo.file.url}?fm=webp`} alt="" className="mx-auto" loading="lazy" />
							</div>
						))}
					</div>
				)}
				<p className="col-span-9 lg:col-span-11 col-start-2 lg:col-start-8 text-center mt-5">
					{cta && (
						<a
							href={cta.href}
							target={cta.openInNewTab ? '_blank' : null}
							rel={cta.openInNewTab ? 'noreferrer noopener' : null}
							className="inline-flex items-center gap-2 type-button border rounded-[4px] px-4 py-2 hover:bg-black hover:text-pureWhite"
						>
							<span>{cta.label}</span>
							<Icon
								icon="arrow-right"
								width="14px"
								height="13px"
								viewBox="0 0 14 13"
								className="fill-currentColor"
							/>
						</a>
					)}
				</p>
				{image && (
					<img
						src={`${image.file.url}?fm=webp`}
						alt=""
						className="col-start-1 lg:col-start-2 col-span-11 lg:col-span-23 h-auto w-full mt-5"
						loading="lazy"
					/>
				)}
			</div>
		</section>
	);
};

export const SectionTextImageFullscreenFragment = graphql`
	fragment SectionTextImageFullscreenFragment on ContentfulSectionTextImageFullscreen {
		headline
		description {
			description
		}
		logos {
			file {
				url
				fileName
			}
		}
		cta {
			href
			label
			openInNewTab
		}
		image {
			file {
				url
				fileName
			}
		}
	}
`;

export default TextImageFullscreen;
