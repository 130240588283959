import { useEffect, useState } from 'react';
import { MOBILE_SIZE } from '~utils/consts';

export const useIsMobile = () => {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const checkIsMobile = () => {
			if (window.innerWidth < MOBILE_SIZE) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		};

		checkIsMobile();
		window.addEventListener('resize', checkIsMobile, false);

		return () => {
			window.removeEventListener('resize', checkIsMobile);
		};
	}, []);

	return isMobile;
};
