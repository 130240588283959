import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { COUNTRIES } from '~utils/consts';

import HexagonalCTA from '~globals/HexagonalCTA';
import RectangularCTA from '~globals/RectangularCTA';
import Icon from '~globals/Icon';
import { useEffect } from 'react';

const DONT_SEE_YOUR_COUNTRY = -1;

const EligibilityForm = ({
	dropdownPlaceholder = 'Select your country',
	ctaLabel = 'Get Started',
	dontSeeYourCountryLabel = "Don't see your country?",
	className,
	isStacked,
	isRectangularButton,
}) => {
	const [country, setCountry] = useState(null);
	const [open, setOpen] = useState(false);
	const openRef = useRef(false);
	const dropdownRef = useRef(null);
	const options = dropdownRef.current
		? [...dropdownRef.current.querySelectorAll('[data-dropdown-option]')]
		: [];

	let buttonLabel;
	let url;
	switch (country) {
		case DONT_SEE_YOUR_COUNTRY:
			buttonLabel = dontSeeYourCountryLabel;
			url = 'https://novacredit.com/country-backlog/';
			break;
		case null:
			buttonLabel = dropdownPlaceholder;
			url = 'https://hello.novacredit.com/get-started';
			break;
		default:
			buttonLabel = (
				<div className="flex items-center">
					<img
						src={`${COUNTRIES[country].flag}`}
						alt=""
						className="inline-block h-4 align-middle mr-2"
					/>
					{COUNTRIES[country].name}
				</div>
			);
			url = `https://hello.novacredit.com/get-started?country=${COUNTRIES[country].value}`;
	}

	const toggle = newState => {
		setOpen(newState);
		openRef.current = newState;
	};

	useEffect(() => {
		const clickOutsideHandler = event => {
			if (!dropdownRef.current.contains(event.target)) {
				event.stopImmediatePropagation();
				event.preventDefault();
				toggle(false);
			}
		};

		const getFocusedIndex = () => {
			return options.findIndex(option => option === document.activeElement);
		};

		const setFocus = index => {
			options[index].focus();
		};

		const keyboardHandler = event => {
			if (event.key === 'Escape') {
				event.stopImmediatePropagation();
				event.preventDefault();
				toggle(false);
				return;
			}

			if (!document.activeElement.hasAttribute('data-dropdown-option')) {
				if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
					event.stopImmediatePropagation();
					event.preventDefault();
					dropdownRef.current.querySelector('[data-dropdown-option]').focus();
				}
			} else {
				if (event.key === 'ArrowUp') {
					event.stopImmediatePropagation();
					event.preventDefault();
					if (getFocusedIndex() === 0) {
						setFocus(options.length - 1);
						return;
					}
					setFocus(getFocusedIndex() - 1);
					return;
				}
				if (event.key === 'ArrowDown') {
					event.stopImmediatePropagation();
					event.preventDefault();
					if (getFocusedIndex() === options.length - 1) {
						setFocus(0);
						return;
					}
					setFocus(getFocusedIndex() + 1);
					return;
				}
			}
		};

		if (open) {
			window.addEventListener('click', clickOutsideHandler);
			window.addEventListener('keydown', keyboardHandler);

			return () => {
				window.removeEventListener('click', clickOutsideHandler);
				window.removeEventListener('keydown', keyboardHandler);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, country]);

	const focusKeyboardHandler = event => {
		if (!openRef.current && (event.key === 'ArrowUp' || event.key === 'ArrowDown')) {
			event.stopImmediatePropagation();
			event.preventDefault();
			toggle(true);
		}
	};

	return (
		<div className={classNames(className, { 'lg:flex': !isStacked })}>
			<div
				className={classNames('relative', { 'shrink grow lg:max-w-sm': !isStacked })}
				ref={dropdownRef}
			>
				<button
					onClick={() => toggle(!open)}
					className={classNames(
						'border rounded w-full py-3 px-4 flex items-center justify-between bg-pureWhite',
						{
							invisible: open,
						},
					)}
					onFocus={() => window.addEventListener('keydown', focusKeyboardHandler)}
					onBlur={() => window.removeEventListener('keydown', focusKeyboardHandler)}
				>
					<span className={classNames('type-body-2', { 'opacity-50': country === null })}>
						{buttonLabel}
					</span>
					<Icon
						icon="chevron-down"
						width="16"
						className={classNames({
							'rotate-180': open,
						})}
					/>
				</button>
				<ul
					className={classNames(
						'eligibility-dropdown border rounded absolute top-0 w-full bg-pureWhite z-10',
						{
							hidden: !open,
						},
					)}
				>
					{COUNTRIES.map(({ flag, name }, index) => (
						<li key={name}>
							<button
								onClick={() => {
									setCountry(index);
									toggle(false);
								}}
								data-dropdown-option
								className="py-3 px-4 flex items-center w-full hover:bg-coolGray-100 focus:bg-coolGray-100"
							>
								<img src={`${flag}`} alt="" className="inline-block h-4 mr-2" />
								<span className="type-body-2">{name}</span>
							</button>
						</li>
					))}

					{/* Uncomment the following <li> to add back "Don't see your country" option */}
					{/* <li>
            <button
              onClick={() => {
                setCountry(DONT_SEE_YOUR_COUNTRY)
                toggle(false)
              }}
              data-dropdown-option
              className="py-3 px-4 flex items-center w-full"
            >
              <span className="type-body-2">{dontSeeYourCountryLabel}</span>
            </button>
          </li> */}
				</ul>
			</div>
			<div
				className={classNames('flex justify-center mt-4', {
					'lg:mt-10': isStacked,
					'lg:mt-0 lg:ml-2': !isStacked,
				})}
			>
				{isRectangularButton ? (
					<RectangularCTA className="w-full lg:w-auto" href={url} text={ctaLabel} />
				) : (
					<HexagonalCTA href={url} text={ctaLabel} />
				)}
			</div>
		</div>
	);
};

export default EligibilityForm;
