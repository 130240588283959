import React, { useState } from 'react';
import classNames from 'classnames';

import Icon from '~globals/Icon';
import SubmenuLink from './SubmenuLink';

const MobileMenuItem = ({ label, href, menu }) => {
	const [active, setActive] = useState(false);

	if (menu) {
		return (
			<li className="flex flex-col justify-center w-full">
				<button
					className={classNames(
						'flex items-center px-6 py-4 rounded type-nav-item-level-1 gap-x-2',
						{ 'bg-black text-pureWhite': active },
					)}
					onClick={() => setActive(!active)}
				>
					<span>{label}</span>
					<Icon
						icon="chevron-down"
						width="16"
						className={classNames({
							'transform rotate-180': active,
						})}
					/>
				</button>
				<div className={active ? 'block' : 'hidden'}>
					<ul>
						{menu.map(({ ctas, links, mobileHeadline, includeCtaOnMobile }, index) => {
							return (
								<li
									className="group py-5 px-6 lg:flex relative cursor-pointer text-left items-center"
									key={index}
								>
									<h3 className="mr-1 type-eyebrow opacity-60">{mobileHeadline}</h3>
									<ul className="pt-4 pb-2 px-3 lg:px-6 top-full gap-x-12">
										{links.map((link, index) => (
											<SubmenuLink
												key={index}
												href={link.href}
												label={link.label}
												icon={link.icon}
												newTab={link.newTab}
											/>
										))}
										{includeCtaOnMobile &&
											ctas &&
											ctas.map((cta, ctaIndex) => (
												<SubmenuLink
													key={ctaIndex}
													href={cta.href}
													label={cta.label}
													icon={cta.icon}
													newTab={cta.newTab}
													isMobileCta={true}
												/>
											))}
									</ul>
								</li>
							);
						})}
					</ul>
				</div>
			</li>
		);
	}
	return (
		<li className="flex flex-col justify-center w-full">
			<a
				className={classNames('px-6 py-4 rounded type-nav-item-level-1 text-left', {
					'bg-black text-pureWhite': active,
				})}
				href={href}
			>
				<span>{label}</span>
			</a>
		</li>
	);
};

export default MobileMenuItem;
