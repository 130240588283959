import React from 'react';

import { CONSUMER_URLS, CMS_URLS, ENV, REDIRECT_TRACKING_DELAY } from '~utils/consts';
import RectangularCTA from '~globals/RectangularCTA';
import { useIsMobile } from '~hooks/useIsMobile';
import useApi from '~hooks/useApi';

const WidgetCard = ({ slug, client }) => {
	const { data: creditCard } = useApi({
		path: `/credit-cards/${slug}`,
	});

	const isMobile = useIsMobile();

	if (!creditCard) {
		return null;
	}

	const consumerBaseURL = CONSUMER_URLS[ENV];
	const cmsBaseURL = CMS_URLS[ENV];
	const isCardAmex = creditCard.manufacturer === 'American Express';
	const applyUrl = isCardAmex
		? `${consumerBaseURL}/cards/redirect/amex/${creditCard.slug}`
		: `${consumerBaseURL}/cards/${creditCard.slug}`;

	const featureClasses = 'pt-[5px] type-body-1 mt-4 lg:mt-0 text-coolGray-900';
	const featureTitleClasses = 'type-eyebrow-small mb-1 lg:mb-2 text-coolGray-700';

	const novaEnabled = creditCard.nova_enabled;

	const handleCtaClick = event => {
		event.preventDefault();
		window.NovaAnalytics.track('CREDIT_CARD_APPLY_CLICKED', {
			page: 'CMS Page',
			cardName: creditCard.name,
			manufacturer: creditCard.manufacturer,
			annualFee: creditCard.annual_fee,
			cashAdvanceAprDescription: creditCard.cash_advance_apr_description,
			creditRating: creditCard.credit_rating,
			id: creditCard.id,
			introPurchaseApr: creditCard.intro_purchase_apr,
			introTransferApr: creditCard.intro_transfer_apr,
			labels: creditCard.labels,
			acceptsSsnOrItin: creditCard.accepts_ssn_or_itin,
			novaEnabled,
			regularPurchaseApr: creditCard.regular_purchase_apr,
			regularTransferApr: creditCard.regular_transfer_apr,
			reportsToAllMajorUsBureaus: creditCard.reports_to_all_major_us_bureaus,
			reportsToSomeMajorUsBureaus: creditCard.reports_to_some_major_us_bureaus,
			secured: creditCard.secured,
		});
		setTimeout(() => {
			window.location.href = applyUrl;
		}, REDIRECT_TRACKING_DELAY);
	};

	const cardImageUrl =
		ENV === 'development' ? creditCard.image_url : `${cmsBaseURL}${creditCard.image_url}`;

	return (
		<div className="border border-coolGray-300 rounded-2xl bg-pureWhite my-10 py-6 sm:text-center">
			<div className="px-4 border-b border-b-coolGray-300">
				<div className="lg:flex items-start">
					<div className="type-eyebrow text-coolGray-700 mb-5">From our partner:</div>
				</div>
				<div className="lg:flex items-start">
					<img
						className="inline-block lg:block w-36 mb-4 lg:mb-0 lg:mr-8"
						alt={creditCard.name}
						src={`${cardImageUrl}?fm=webp`}
					/>
					<div className="shrink grow">
						<a className="block type-h5 font-medium mb-2" href={`${consumerBaseURL}/cards/${slug}`}>
							{creditCard.name}
						</a>
						<p className="type-body-2 font-serif mb-6">{creditCard.bullets[0]} Terms apply.</p>
						<div className="lg:flex items-center mb-8">
							<RectangularCTA
								className="mb-4 lg:mb-0 lg:mr-8"
								text="Apply Now"
								href={applyUrl}
								onClick={handleCtaClick}
							/>
							<div>
								<a
									className="block text-blue-700 type-body-1"
									href={creditCard.terms_url}
									target="_blank"
									rel="noopener noreferrer"
								>
									Rates and Fees
								</a>
								<p className="text-coolGray-900 type-body-0">Terms apply</p>
							</div>
							{novaEnabled && isMobile && (
								<div className="block flex-1 type-body-1 text-emerald-700 mx-8 mt-2">
									Accepts foreign credit history through Nova Credit
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="px-4 lg:flex pt-6">
				{novaEnabled && !isMobile && (
					<div className="block grow-0 shrink-0 lg:w-36 lg:mr-8 type-body-1 text-emerald-700">
						Accepts Nova Credit
					</div>
				)}
				<div className="shrink grow lg:grid lg:grid-cols-3 lg:gap-8">
					<div className={featureClasses}>
						<div className={featureTitleClasses}>Annual fee</div>
						<div>{creditCard.annual_fee_description}</div>
					</div>
					<div className={featureClasses}>
						<div className={featureTitleClasses}>Intro Purchase APR</div>
						<div>
							{creditCard.intro_purchase_apr_description}
							{creditCard.intro_purchase_apr !== null &&
								creditCard.intro_purchase_apr_period_description !== 'N/A' &&
								creditCard.intro_purchase_apr_period_description && (
									<> for {creditCard.intro_purchase_apr_period_description}</>
								)}
						</div>
					</div>
					<div className={featureClasses}>
						<div className={featureTitleClasses}>Regular APR</div>
						<div>{creditCard.regular_purchase_apr_description}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WidgetCard;
