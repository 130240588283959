import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

const Quote = ({ shape, text, isOnRight, author, role, logo, backgroundColor }) => (
	<section
		className={classNames({
			'bg-pureWhite': backgroundColor === 'white',
			'bg-coolGray-50': backgroundColor === 'gray-50',
			'bg-coolGray-100': backgroundColor === 'gray-100',
		})}
	>
		<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl py-16 lg:py-12">
			<div
				className={classNames('grid items-center justify-center col-start-2 col-span-9', {
					'lg:col-start-5 lg:col-span-17 text-center': !isOnRight,
					'lg:col-start-8 lg:col-span-15': isOnRight,
				})}
			>
				<img
					className={'w-full hidden lg:block row-start-1 col-start-1'}
					src={`${shape.file.url}?fm=webp`}
					alt=""
				/>
				<div className="w-full row-start-1 col-start-1">
					{!isOnRight && (
						<>
							<h3 className="type-quote">“{text.text}”</h3>
							<p className="type-body-1 mt-5 font-serif font-medium mb-2">
								{author} | <em>{role}</em>
							</p>
							<img className="inline-block w-2-col" src={`${logo.file.url}?fm=webp`} alt="" />
						</>
					)}
					{isOnRight && (
						<>
							<h3 className="relative type-quote before:content-['“'] before:absolute before:-left-5">
								{text.text}”
							</h3>
							<div className="mt-8 items-center lg:flex lg:flex-nowrap">
								<p className="type-body-1 font-serif mb-2 lg:mb-0 lg:flex lg:order-2">
									{author}
									<span className="lg:block mx-1 lg:mx-3"> | </span>
									<em className="font-thin italic">{role}</em>
								</p>
								<img
									className="inline-block w-2-col mr-14 lg:order-1"
									src={`${logo.file.url}?fm=webp`}
									alt=""
								/>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	</section>
);

export const SectionQuoteFragment = graphql`
	fragment SectionQuoteFragment on ContentfulSectionQuote {
		backgroundColor
		shape {
			file {
				url
			}
		}
		text {
			text
		}
		isOnRight
		author
		role
		logo {
			file {
				url
			}
		}
	}
`;

export default Quote;
