import React from 'react';
import RootWrap from '~globals/RootWrap';

export const wrapRootElement = ({ element }) => {
	return <RootWrap>{element}</RootWrap>;
};

export const shouldUpdateScroll = apiCallbackContext => {
	return !!apiCallbackContext.routerProps.location.hash;
};
