import React, { useState } from 'react';
import classNames from 'classnames';

import MenuDropdown from '~globals/Menu/MenuDropdown';
import Icon from '~globals/Icon';
import { useLanguage } from '~contexts/Language';

import './styles.css';

const LANGUAGE_NAME_MAP = {
	en: 'English',
	fr: 'Français Canadien',
};

export const MobileLanguageDropdown = () => {
	const [active, setActive] = useState(false);
	const { language, setLanguage } = useLanguage();

	const languageOptions = Object.keys(LANGUAGE_NAME_MAP).map((lang, index) => (
		<li
			className="flex items-start max-w-[420px] gap-x-2 py-3 hover:opacity-60 lg:pr-4"
			key={index}
		>
			<button className="mr-1 type-body-2 cursor-pointer" onClick={() => setLanguage(lang)}>
				{LANGUAGE_NAME_MAP[lang]}
			</button>
		</li>
	));

	return (
		<li className="mobile-language-dropdown flex flex-col justify-center w-full">
			<button
				className={classNames('flex items-center px-6 py-4 rounded type-nav-item-level-1 gap-x-2', {
					'bg-black text-pureWhite': active,
				})}
				onClick={() => setActive(!active)}
			>
				<Icon
					icon="translate"
					width="20"
					className={classNames('uq-menu-dropdown-left-icon', { active })}
				/>
				<span>{LANGUAGE_NAME_MAP[language]}</span>
				<Icon
					icon="chevron-down"
					width="16"
					className={classNames({
						'transform rotate-180': active,
					})}
				/>
			</button>
			<div className={active ? 'block' : 'hidden'}>
				<ul>
					<li className="group py-5 px-6 lg:flex relative cursor-pointer text-left items-center">
						<ul className="pt-4 pb-2 px-3 lg:px-6 top-full gap-x-12">{languageOptions}</ul>
					</li>
				</ul>
			</div>
		</li>
	);
};

const LanguageDropdown = () => {
	const { language, setLanguage } = useLanguage();

	const languageOptions = [
		{
			links: Object.keys(LANGUAGE_NAME_MAP).map(lang => {
				return {
					label: LANGUAGE_NAME_MAP[lang],
					onClick: () => setLanguage(lang),
				};
			}),
		},
	];

	return (
		<MenuDropdown
			className="language-dropdown"
			title={LANGUAGE_NAME_MAP[language]}
			columns={languageOptions}
			align={'right'}
			icon={'translate'}
		/>
	);
};

export default LanguageDropdown;
