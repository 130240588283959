import React from 'react';

import HexagonalButton from '~globals/HexagonalButton';
import Icon from '~globals/Icon';

const HexagonalCTA = ({
	className,
	text,
	href,
	type,
	newTab,
	noArrow,
	redirectedToCustomerEventCustomerName,
	buttonType,
}) => {
	return (
		<HexagonalButton
			href={href}
			type={type}
			newTab={newTab}
			className={className}
			redirectedToCustomerEventCustomerName={redirectedToCustomerEventCustomerName}
			buttonType={buttonType}
		>
			<span>{text}</span>
			{!noArrow && <Icon icon="arrow-right" width="14px" />}
		</HexagonalButton>
	);
};

export default HexagonalCTA;
