import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { getCurrentUrl } from '~utils/urls';

import PageTitleContext from '~contexts/PageTitle';
import { useLanguage } from '~contexts/Language';
import Layout from 'src/components/globals/Layout';

import Awards from '~sections/Awards';
import CaseStudies from '~sections/CaseStudies';
import CheckEligibility from '~sections/CheckEligibility';
import Countries from '~sections/Countries';
import ContactForm from '~sections/ContactForm';
import Copy from '~sections/Copy';
import DataInfo from '~sections/DataInfo';
import Faq from '~sections/Faq';
import Hero from '~sections/Hero';
import VideoModal from '~sections/VideoModal';
import HowItWorks from '~sections/HowItWorks';
import ImageCopy from '~sections/ImageCopy';
import LogoWall from '~sections/LogoWall';
import MoreWays from '~sections/MoreWays';
import Partners from '~sections/Partners';
import PostList from '~sections/PostList';
import PostBody from '~sections/PostBody';
import Quote from '~sections/Quote';
import Resources from '~sections/Resources';
import Team from '~sections/Team';
import TextImageFullscreen from '~sections/TextImageFullscreen';
import ThreeColumns from '~sections/ThreeColumns';
import ThreeColumnsCentered from '~sections/ThreeColumnsCentered';
import TwoProducts from '~sections/TwoProducts';
import WhyProduct from '~sections/WhyProduct';

const Page = ({ data }) => {
	const { language } = useLanguage();
	const {
		sections,
		parentGroup,
		navigationRegion,
		title,
		metaTitle,
		hideHeaderNav,
		hideFooter,
		bannerText,
		excludeBanner,
		noIndex,
		metaDescription,
		metaImage,
		slug,
	} = data[language] ?? data.contentfulPage;
	const { defaultMetaDescription, defaultMetaImage } = data.contentfulGlobalSettings;
	const [displayTitle, setDisplayTitle] = useState(
		metaTitle || title ? `${metaTitle?.trim() ?? title?.trim()} | Nova Credit` : 'Nova Credit',
	);

	// this is needed to handle language changes
	useEffect(() => {
		setDisplayTitle(
			metaTitle || title ? `${metaTitle?.trim() ?? title?.trim()} | Nova Credit` : 'Nova Credit',
		);
	}, [metaTitle, title]);

	if (!sections)
		return (
			<Layout
				parentGroup={parentGroup || 'individual'}
				navigationRegion={navigationRegion}
				hideHeaderNav={hideHeaderNav}
				hideFooter={hideFooter}
				noIndex={noIndex}
				bannerText={bannerText?.bannerText}
				excludeBanner={excludeBanner}
			>
				<div className="pt-80 pb-40 text-center">Empty page</div>
			</Layout>
		);
	const description = metaDescription || defaultMetaDescription;
	const image = metaImage ? metaImage.url : defaultMetaImage.url;
	const url = getCurrentUrl(undefined, slug);
	return (
		<PageTitleContext.Provider value={displayTitle}>
			<Layout
				parentGroup={parentGroup || 'individual'}
				navigationRegion={navigationRegion}
				hideHeaderNav={hideHeaderNav}
				hideFooter={hideFooter}
				noIndex={noIndex}
				bannerText={bannerText?.bannerText}
				excludeBanner={excludeBanner}
			>
				<Helmet>
					<title>{displayTitle}</title>
					<meta name="name" content={displayTitle} />
					<meta name="url" content={url} />
					<meta name="description" content={description} />

					<meta property="og:title" content={displayTitle} />
					<meta property="og:site_name" content="Nova Credit" />
					<meta property="og:type" content="website" />
					<meta property="og:url" content={url} />
					<meta property="og:image" content={image} />
					<meta property="og:description" content={description} />

					<meta name="twitter:title" content={displayTitle} />
					<meta name="twitter:url" content={url} />
					<meta name="twitter:card" content="summary_large_image" />
					<meta name="twitter:description" content={description} />
					<meta name="twitter:image" content={image} />

					<link rel="canonical" href={url} />
				</Helmet>
				{sections.map(({ __typename, ...sectionData }, index) => {
					switch (__typename) {
						case 'ContentfulSectionAwards':
							return <Awards key={index} {...sectionData} />;
						case 'ContentfulSectionCaseStudies':
							return <CaseStudies key={index} {...sectionData} />;
						case 'ContentfulSectionCheckEligibility':
							return <CheckEligibility key={index} {...sectionData} />;
						case 'ContentfulSectionCountries':
							return <Countries key={index} {...sectionData} />;
						case 'ContentfulSectionContactForm':
							return <ContactForm key={index} {...sectionData} />;
						case 'ContentfulSectionCopy':
							return <Copy key={index} {...sectionData} />;
						case 'ContentfulSectionDataInfo':
							return <DataInfo key={index} {...sectionData} />;
						case 'ContentfulSectionFaq':
							return <Faq key={index} {...sectionData} />;
						case 'ContentfulSectionHero':
							return <Hero key={index} {...sectionData} />;
						case 'ContentfulSectionVideoModal':
							return <VideoModal key={index} {...sectionData} />;
						case 'ContentfulSectionHowItWorks':
							return <HowItWorks key={index} {...sectionData} />;
						case 'ContentfulSectionImageCopy':
							return <ImageCopy key={index} {...sectionData} />;
						case 'ContentfulSectionLogoWall':
							return <LogoWall key={index} {...sectionData} />;
						case 'ContentfulSectionMoreWays':
							return <MoreWays key={index} {...sectionData} />;
						case 'ContentfulSectionPartners':
							return <Partners key={index} {...sectionData} />;
						case 'ContentfulSectionPostList':
							return <PostList key={index} {...sectionData} />;
						case 'ContentfulSectionPostBody':
							return <PostBody key={index} {...sectionData} />;
						case 'ContentfulSectionQuote':
							return <Quote key={index} {...sectionData} />;
						case 'ContentfulSectionResources':
							return <Resources key={index} {...sectionData} />;
						case 'ContentfulSectionTeam':
							return <Team key={index} {...sectionData} />;
						case 'ContentfulSectionTextImageFullscreen':
							return <TextImageFullscreen key={index} {...sectionData} />;
						case 'ContentfulSectionThreeColumns':
							return <ThreeColumns key={index} {...sectionData} />;
						case 'ContentfulSectionThreeColumnsCentered':
							return <ThreeColumnsCentered key={index} {...sectionData} />;
						case 'ContentfulSectionTwoProducts':
							return <TwoProducts key={index} {...sectionData} />;
						case 'ContentfulSectionWhyProduct':
							return <WhyProduct key={index} {...sectionData} />;
						default:
							console.error(`Component doesn’t exist for section ${__typename}`);
							return null;
					}
				})}
			</Layout>
		</PageTitleContext.Provider>
	);
};

export const Head = () => {
	return (
		<>
			<link rel="preconnect" href="https://fonts.googleapis.com" />
			<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
			<link
				href="https://fonts.googleapis.com/css2?family=Newsreader:opsz@6..72&display=swap"
				rel="stylesheet"
			></link>
		</>
	);
};

export const query = graphql`
	fragment pageFields on ContentfulPage {
		title
		metaTitle
		slug
		metaDescription
		metaImage {
			url
		}
		parentGroup
		navigationRegion
		hideHeaderNav
		hideFooter
		bannerText {
			bannerText
		}
		excludeBanner
		noIndex
		sections {
			__typename
			... on ContentfulSectionAwards {
				...SectionAwardsFragment
			}
			... on ContentfulSectionCaseStudies {
				...SectionCaseStudiesFragment
			}
			... on ContentfulSectionCheckEligibility {
				...SectionCheckEligibilityFragment
			}
			... on ContentfulSectionCountries {
				...SectionCountriesFragment
			}
			... on ContentfulSectionContactForm {
				...SectionContactFormFragment
			}
			... on ContentfulSectionCopy {
				...SectionCopyFragment
			}
			... on ContentfulSectionDataInfo {
				...SectionDataInfoFragment
			}
			... on ContentfulSectionFaq {
				...SectionFaqFragment
			}
			... on ContentfulSectionHero {
				...SectionHeroFragment
			}
			... on ContentfulSectionVideoModal {
				...SectionVideoModalFragment
			}
			... on ContentfulSectionHowItWorks {
				...SectionHowItWorksFragment
			}
			... on ContentfulSectionImageCopy {
				...SectionImageCopyFragment
			}
			... on ContentfulSectionLogoWall {
				...SectionLogoWallFragment
			}
			... on ContentfulSectionMoreWays {
				...SectionMoreWaysFragment
			}
			... on ContentfulSectionPartners {
				...SectionPartnersFragment
			}
			... on ContentfulSectionPostBody {
				...SectionPostBodyFragment
			}
			... on ContentfulSectionPostList {
				...SectionPostListFragment
			}
			... on ContentfulSectionQuote {
				...SectionQuoteFragment
			}
			... on ContentfulSectionResources {
				...SectionResourcesFragment
			}
			... on ContentfulSectionTeam {
				...SectionTeamFragment
			}
			... on ContentfulSectionTextImageFullscreen {
				...SectionTextImageFullscreenFragment
			}
			... on ContentfulSectionThreeColumns {
				...SectionThreeColumnsFragment
			}
			... on ContentfulSectionThreeColumnsCentered {
				...SectionThreeColumnsCenteredFragment
			}
			... on ContentfulSectionTwoProducts {
				...SectionTwoProductsFragment
			}
			... on ContentfulSectionWhyProduct {
				...SectionWhyProductFragment
			}
		}
	}

	query PageQuery($contentful_id: String) {
		en: contentfulPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: "en-US" }) {
			...pageFields
		}
		fr: contentfulPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: "fr-CA" }) {
			...pageFields
		}
		contentfulGlobalSettings {
			defaultMetaDescription
			defaultMetaImage {
				url
			}
		}
	}
`;

export default Page;
