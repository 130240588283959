import React, { useState, useEffect, useCallback } from 'react';
import { graphql } from 'gatsby';
import { renderLongText } from '~utils/richText';
import EmailForm from '~globals/EmailForm';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';

import './styles.css';

const ContactForm = ({
	simpleEmailSignup,
	formUrl,
	formId,
	title,
	description,
	frameColor,
	backgroundColor,
}) => {
	const formContainerId = `hubspotFormContainer_${formId}`;
	const [height, setHeight] = useState(null);

	const initializeHubspotForm = useCallback(() => {
		if ('hbspt' in window) {
			window.hbspt.forms.create({
				region: 'na1',
				portalId: '46220126',
				target: `#${formContainerId}`,
				formId,
				css: '',
			});
		} else {
			setTimeout(initializeHubspotForm, 500);
		}
	}, [formId, formContainerId]);

	useEffect(() => {
		window.addEventListener('message', event => {
			setHeight(event.data);
		});
		if (formId) {
			initializeHubspotForm();
		}
	}, [formId, initializeHubspotForm]);

	return (
		<section
			id="contact-form"
			className={classNames('pb-10', {
				'bg-coolGray-100': backgroundColor === 'gray-100',
				'bg-pureWhite': backgroundColor === 'white',
			})}
		>
			<div
				className={classNames(
					'grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl py-24 rounded-2xl gap-y-9',
					{
						'bg-coolGray-300': frameColor === 'gray',
						'bg-blue-50': frameColor === 'blue',
						'bg-emerald-300': frameColor === 'emerald',
					},
				)}
			>
				<div className="col-span-9 lg:col-span-6 col-start-2 lg:col-start-4">
					<h3 className="type-h3">{title}</h3>
					{description && (
						<div className="mt-4 font-serif formatted-text" id="email-signup-description">
							{renderLongText(description.description)}
						</div>
					)}
				</div>
				<div className="col-span-9 lg:col-span-9 col-start-2 lg:col-start-13">
					{simpleEmailSignup && (
						<EmailForm
							ariaLabelledBy="email-signup-description"
							analyticsLocation="Email Signup CTA"
						/>
					)}
					{!simpleEmailSignup && formUrl && !formId && (
						// TODO: remove this block once all forms are migrated to HubSpot
						<iframe
							title="Pardot Lead Capture Form"
							src={formUrl}
							scrolling="no"
							width="100%"
							height="600"
							frameBorder="0"
							style={{ paddingTop: 0, height: height ? `${height}px` : 'auto' }}
						></iframe>
					)}
					{!simpleEmailSignup && formId && (
						<>
							<Helmet>
								<script type="text/javascript" src="//js.hsforms.net/forms/shell.js"></script>
							</Helmet>
							<div
								id={formContainerId}
								className="hubspotFormContainer"
								width="100%"
								style={{ paddingTop: 0 }}
							></div>
						</>
					)}
				</div>
			</div>
		</section>
	);
};

export const SectionContactFormFragment = graphql`
	fragment SectionContactFormFragment on ContentfulSectionContactForm {
		title
		description {
			description
		}
		simpleEmailSignup
		formUrl
		formId
		frameColor
		backgroundColor
	}
`;

export default ContactForm;
