import React from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import CaseStudyCard from './CaseStudyCard';

import CTA from '~globals/CTA';
import VideoCTA from '~globals/VideoCTA';
import HexagonalCTA from '~globals/HexagonalCTA';
import FancyBorder from '~globals/FancyBorder';

import Trustpilot from '~sections/Trustpilot';

const CaseStudies = ({
	backgroundColor,
	topBorder,
	caseStudies,
	title,
	buttons,
	includeTrustpilot,
}) => {
	return (
		<section
			className={classNames({
				'bg-pureWhite': backgroundColor === 'white',
				'bg-coolGray-50': backgroundColor === 'gray-50',
			})}
		>
			{topBorder && (
				<FancyBorder
					type={`top-${topBorder}`}
					background={backgroundColor === 'white' ? 'gray' : 'white'}
					foreground={backgroundColor}
				/>
			)}

			{/* Trustpilot should be it's own section type but we ran out of ContentTypes */}
			{includeTrustpilot && <Trustpilot />}

			{(title || buttons) && (
				<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl items-center">
					<div className="col-start-2 lg:col-start-5 col-span-9 lg:col-span-17 text-center mt-16">
						<h2 className="type-h3">{title}</h2>
						{buttons && (
							<div className="my-8 flex gap-x-10 justify-center">
								{buttons.map((button, index) => {
									if (button.video) {
										return (
											<VideoCTA
												label={button.label}
												video={button.video.url}
												key={index}
												buttonType={button.style}
											/>
										);
									} else if (index === 0) {
										return (
											<HexagonalCTA
												href={button.href}
												text={button.label}
												newTab={button.openInNewTab}
												key={index}
												buttonType={button.style}
											/>
										);
									} else {
										return (
											<CTA
												href={button.href}
												text={button.label}
												newTab={button.openInNewTab}
												key={index}
											/>
										);
									}
								})}
							</div>
						)}
					</div>
				</div>
			)}
			<div className="py-8 flex gap-x-1-col w-full overflow-auto snap-x before:content-[''] before:w-[9px] lg:before:w-8 before:shrink-0 after:content-[''] after:w-[9px] lg:after:w-8 after:shrink-0">
				{caseStudies.map((caseStudy, index) => (
					<CaseStudyCard {...caseStudy} key={index} />
				))}
			</div>
		</section>
	);
};

export const SectionCaseStudiesFragment = graphql`
	fragment SectionCaseStudiesFragment on ContentfulSectionCaseStudies {
		title
		buttons {
			label
			href
			video {
				url
			}
			openInNewTab
			style
		}
		caseStudies {
			eyebrow
			title
			description {
				description
			}
			color
			cta {
				label
				href
				openInNewTab
			}
			logo {
				file {
					fileName
					url
				}
			}
			image {
				file {
					fileName
					url
				}
				description
			}
		}
		backgroundColor
		topBorder
		includeTrustpilot
	}
`;

export default CaseStudies;
