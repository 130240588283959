import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import { renderRichText } from '~utils/richText';
import useIntersectionObserver from '~hooks/useIntersectionObserver';

import CTA from '~globals/CTA';
import VideoCTA from '~globals/VideoCTA';
import HexagonalCTA from '~globals/HexagonalCTA';
import FancyBorder from '~globals/FancyBorder';

const Image = ({ images, right, largeHeadline }) => {
	const ref = useRef(null);
	const entry = useIntersectionObserver(ref, {
		rootMargin: `0px 0px 0px 0px`,
	});
	const isVisible = !!entry?.isIntersecting;

	useEffect(() => {
		if (isVisible && ref.current) {
			const handleScroll = () => {
				const progress =
					(window.scrollY - (ref.current.offsetTop - window.innerHeight)) /
					(window.innerHeight + ref.current.clientHeight);
				ref.current.style.setProperty('--scroll-progress', progress);
			};

			window.addEventListener('scroll', handleScroll);

			return () => {
				window.removeEventListener('scroll', handleScroll);
			};
		}
	}, [isVisible]);

	return (
		<div
			ref={ref}
			className={classNames('col-start-2 col-span-9 mb-[20%] lg:mb-[10%] rounded-2xl relative', {
				'lg:col-start-14 lg:col-span-11 lg:order-2': largeHeadline,
				'lg:col-start-3 lg:col-span-10': !largeHeadline && right,
				'lg:col-start-14 lg:col-span-10 lg:order-2': !largeHeadline && right,
			})}
		>
			{images &&
				images.map((image, index) => (
					<img
						key={index}
						className={classNames('block w-full', {
							relative: index === 0,
							'absolute top-0 left-0': index !== 0,
						})}
						src={`${image.file.url}?fm=webp`}
						style={{
							transform: `translateY(calc((var(--scroll-progress) - 1) * 15% * ${-index - 1}))`,
						}}
						loading="lazy"
						alt=""
					/>
				))}
		</div>
	);
};

const ImageCopy = ({
	sectionId,
	headline,
	largeHeadline,
	subtitle,
	body,
	images,
	imageRightAligned,
	disclaimer,
	buttons,
	logosLabel,
	logos,
	quote,
	quoteAuthor,
	quoteLogo,
	backgroundColor,
	topBorder,
	topBorderColor,
}) => {
	return (
		<section
			className={classNames({
				'bg-pureWhite': backgroundColor === 'white',
				'bg-coolGray-50': backgroundColor === 'gray-50',
				'bg-coolGray-100': backgroundColor === 'gray-100',
			})}
		>
			{topBorder && (
				<FancyBorder
					type={`top-${topBorder}`}
					background={topBorderColor}
					foreground={backgroundColor}
				/>
			)}
			<div
				className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl py-10 items-center lg:order-2"
				id={sectionId}
			>
				{!imageRightAligned && (
					<Image images={images} right={imageRightAligned} largeHeadline={largeHeadline} />
				)}
				<div
					className={classNames('col-start-2 col-span-9 lg:col-span-9 order-2 lg:order-1', {
						'lg:col-start-3 lg:col-span-8': largeHeadline,
						'lg:col-start-4 lg:col-span-9': !largeHeadline && imageRightAligned,
						'lg:col-start-14 lg:col-span-9': !largeHeadline && !imageRightAligned,
					})}
				>
					<div>
						<h4 className={largeHeadline ? 'type-h2 mb-10 hyphens' : 'type-h3 mb-4 hyphens'}>
							{headline}
						</h4>
						{subtitle && <p className="type-body-3 mb-2 font-[450]">{subtitle}</p>}
						<div className="type-body-3 font-serif formatted-text">{renderRichText(body)}</div>
						{disclaimer && (
							<div className="type-body-2 text-coolGray-700 mt-16">{disclaimer.disclaimer}</div>
						)}
						{buttons && (
							<div className="mt-10 flex gap-x-10">
								{buttons.map((button, index) => {
									if (button.video) {
										return (
											<VideoCTA
												label={button.label}
												video={button.video.url}
												key={index}
												buttonType={button.style}
											/>
										);
									} else if (index === 0) {
										return (
											<HexagonalCTA
												href={button.href}
												text={button.label}
												newTab={button.openInNewTab}
												key={index}
												redirectedToCustomerEventCustomerName={
													button.redirectedToCustomerEventCustomerName
												}
												buttonType={button.style}
											/>
										);
									} else {
										return (
											<CTA
												href={button.href}
												text={button.label}
												newTab={button.openInNewTab}
												key={index}
												redirectedToCustomerEventCustomerName={
													button.redirectedToCustomerEventCustomerName
												}
											/>
										);
									}
								})}
							</div>
						)}
					</div>
				</div>
				{imageRightAligned && (
					<Image images={images} right={imageRightAligned} largeHeadline={largeHeadline} />
				)}
				{logos && (
					<div
						className={classNames(
							'col-start-2 col-span-9 lg:col-span-9 order-2 flex flex-wrap lg:flex-nowrap items-start mt-4 text-center lg:text-left gap-x-8 justify-center lg:justify-start lg:gap-8',
							{
								'lg:col-start-3': !imageRightAligned,
								'lg:col-start-14 lg:order-2': imageRightAligned,
							},
						)}
					>
						{logosLabel && (
							<p className="type-body-1 font-serif font-medium lg:w-32 lg:mt-5 basis-full lg:basis-auto">
								{logosLabel}
							</p>
						)}

						<div className="flex flex-wrap basis-full lg:basis-auto lg:shrink items-center gap-x-8 justify-center lg:justify-start lg:gap-8">
							{logos.map((logo, index) => (
								<div className="basis-2/5 lg:basis-auto" key={index}>
									<img
										src={logo.file.url}
										alt={logo.description}
										className="h-[80px] max-w-[200px] mx-auto"
										loading="lazy"
									/>
								</div>
							))}
						</div>
					</div>
				)}

				{quote && (
					<div
						className={classNames('col-start-2 col-span-9 lg:col-span-9 order-3 mt-16 lg:mt-4', {
							'lg:col-start-4': !imageRightAligned,
							'lg:col-start-15 lg:order-2': imageRightAligned,
						})}
					>
						<p className="type-body-3 relative font-serif before:content-['“'] before:absolute before:-left-3">
							{quote.quote}”
						</p>
						<div className="mt-8 flex items-end flex-wrap lg:flex-nowrap">
							<img
								className="max-h-8 order-2 lg:-order-none mt-3 lg:mt-0"
								src={`${quoteLogo.file.url}?fm=webp`}
								alt={quoteLogo.fileName}
								loading="lazy"
							/>
							<span className="hidden lg:block mx-3"> | </span>
							<p className="w-full lg:w-auto type-body-2 italic font-serif">{quoteAuthor}</p>
						</div>
					</div>
				)}
			</div>
		</section>
	);
};

export const SectionImageCopyFragment = graphql`
	fragment SectionImageCopyFragment on ContentfulSectionImageCopy {
		sectionId
		headline
		largeHeadline
		subtitle
		body {
			raw
		}
		disclaimer {
			disclaimer
		}
		buttons {
			label
			href
			video {
				url
			}
			openInNewTab
			redirectedToCustomerEventCustomerName
			style
		}
		images {
			file {
				fileName
				url
			}
		}
		imageRightAligned
		logosLabel
		logos {
			file {
				url
			}
			description
		}
		quote {
			quote
		}
		quoteAuthor
		quoteLogo {
			file {
				url
			}
		}
		backgroundColor
		topBorder
		topBorderColor
	}
`;

export default ImageCopy;
