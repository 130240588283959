import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { renderLongText } from '~utils/richText';
import Icon from '~globals/Icon';
import { useState } from 'react';

const Banner = ({ text }) => {
	const data = useStaticQuery(graphql`
		query BannerMenuQuery {
			contentfulGlobalSettings {
				globalBannerText {
					globalBannerText
				}
				enableGlobalBanner
			}
		}
	`).contentfulGlobalSettings;

	const [closed, setClosed] = useState(false);

	if (closed || (!data.enableGlobalBanner && !text)) {
		return null;
	}

	const bannerText = text || data.globalBannerText.globalBannerText;

	return (
		<div className="bg-blue-700 text-pureWhite text-center fixed bottom-0 left-0 right-0 px-4 py-6 lg:px-14 lg:py-3 transition-opacity sm:type-body-1">
			{renderLongText(bannerText)}

			<button
				className="p-1 right-0 lg:p-3 lg:right-2 top-0 absolute"
				onClick={() => setClosed(true)}
			>
				<Icon icon="close" className="w-5 h-5 lg:w-6 lg:h-6" />
			</button>
		</div>
	);
};

export default Banner;
