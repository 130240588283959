import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';

import { useScrollDirection } from '~hooks/useScrollDirection';
import { usePageScrolled } from '~hooks/usePageScrolled';
import HexagonalCTA from '~globals/HexagonalCTA';
import Icon from '~globals/Icon';
import { PAGE_NAVIGATION_REGION } from '~utils/consts';
import LanguageDropdown, { MobileLanguageDropdown } from '~globals/LanguageDropdown';
import { useLanguage } from '~contexts/Language';
import MenuDropdown from './MenuDropdown';
import MobileMenuItem from './MobileMenuItem';

import './styles.css';

const Menu = ({ parentGroup, navigationRegion, hideHeaderNav }) => {
	const { language } = useLanguage();

	const data = useStaticQuery(graphql`
		fragment MenuTab on ContentfulGlobalMenuTab {
			label
			href
			name
			menu {
				title
				columns {
					columnHeadline
					links {
						icon {
							url
						}
						href
						label
						newTab
					}
					ctas {
						icon {
							url
						}
						href
						label
						newTab
					}
				}
			}
			mobileMenu {
				mobileHeadline
				links {
					icon {
						url
					}
					href
					label
					newTab
				}
				ctas {
					icon {
						url
					}
					href
					label
					newTab
				}
				includeCtaOnMobile
			}
			cta {
				label
				newTab
				href
			}
		}

		fragment contentfulGlobalSettingsMenu on ContentfulGlobalSettings {
			tabs {
				...MenuTab
			}
			tabsUk {
				label
				href
				name
				# @LOCALE: Submenus and mobile menus have been turned off for UK
				# To turn them back on, simply use "...MenuTab" instead
			}
			tabsUae {
				...MenuTab
			}
			tabsCan {
				...MenuTab
			}
			aboutUsDropdownTitle
			aboutUsDropdownLinks {
				cta {
					icon {
						url
					}
					href
					label
					newTab
				}
				links {
					label
					href
					newTab
					icon {
						url
					}
				}
				mobileHeadline
				includeCtaOnMobile
			}
		}

		query MenuQuery {
			en: contentfulGlobalSettings(node_locale: { eq: "en-US" }) {
				...contentfulGlobalSettingsMenu
			}
			fr: contentfulGlobalSettings(node_locale: { eq: "fr-CA" }) {
				...contentfulGlobalSettingsMenu
			}
		}
	`)[language ?? 'en'];
	let showLanguageToggle = false;
	let MENU_CONTENT, ABOUT_DROPDOWN_CONTENT;
	switch (navigationRegion) {
		case PAGE_NAVIGATION_REGION.UK:
			MENU_CONTENT = data.tabsUk;
			// @LOCALE: About us dropdown has been turned off for UK
			// To turn it back on, uncomment the following lines:
			// ABOUT_DROPDOWN_CONTENT = {
			//   title: data.aboutUsDropdownTitle,
			//   dropdown: data.aboutUsDropdownLinks,
			// }
			break;
		case PAGE_NAVIGATION_REGION.UAE:
			MENU_CONTENT = data.tabsUae;
			ABOUT_DROPDOWN_CONTENT = {
				title: data.aboutUsDropdownTitle,
				dropdown: data.aboutUsDropdownLinks,
			};
			break;
		case PAGE_NAVIGATION_REGION.CAN:
			MENU_CONTENT = data.tabsCan;
			// @LOCALE: About us dropdown has been turned off for Canada
			// To turn it back on, uncomment the following lines:
			// ABOUT_DROPDOWN_CONTENT = {
			// 	title: data.aboutUsDropdownTitle,
			// 	dropdown: data.aboutUsDropdownLinks,
			// };
			showLanguageToggle = true;
			break;
		default:
			MENU_CONTENT = data.tabs;
			ABOUT_DROPDOWN_CONTENT = {
				title: data.aboutUsDropdownTitle,
				dropdown: data.aboutUsDropdownLinks,
			};
	}

	const activeTab = parentGroup ? MENU_CONTENT.findIndex(tab => tab.name === parentGroup) : 0;
	const [burgerOpen, setBurgerOpen] = useState(false);
	const scrollDirection = useScrollDirection();
	const pageScrolled = usePageScrolled();
	const menuRef = useRef();

	const blurOtherTabs = () => {
		const tabs = document.querySelector('[data-main-menu-tabs]');
		if (tabs?.contains(document.activeElement)) {
			document.activeElement.blur();
		}
	};

	return (
		<div
			className={classNames('fixed w-full transition-transform z-50', {
				'transform translate-y-[-64px] lg:translate-y-[-60px]':
					scrollDirection === 'down' && !burgerOpen,
				'bg-coolGray-50': pageScrolled || burgerOpen,
			})}
			ref={menuRef}
		>
			<div className="relative flex justify-between items-center lg:border-b pl-3 lg:pl-10 pr-2 lg:pr-4">
				<a
					className="flex h-[59px] items-center"
					href={MENU_CONTENT[activeTab].href}
					title="Nova Credit"
				>
					<Icon icon="logo" width="177" />
				</a>
				<a
					className="absolute sm:hidden lg:left-60 opacity-0 pointer-events-none focus:opacity-100 focus:pointer-events-auto"
					href="#main-content"
				>
					Skip navigation
				</a>
				{!hideHeaderNav && (
					<>
						<div role="tablist" data-main-menu-tabs className="menu-tab-list hidden lg:flex">
							{MENU_CONTENT.map(({ name, label, href, cta, menu }, index) => (
								<div
									className={classNames('menu-tab', {
										'menu-tab--default': activeTab === index,
									})}
									key={index}
								>
									<a
										className="menu-tab-name type-nav-item-level-1 pt-5 pb-[calc(1.25rem-4px)] px-6 border-b-4 border-b-transparent hover:opacity-70 h-[59px]"
										id={name}
										role="tab"
										href={href}
										onMouseEnter={blurOtherTabs}
									>
										{label}
									</a>
									<div
										className={classNames(
											'menu-tab-content absolute left-0 top-[calc(100%+1px)] w-full border-b border-transparent transition-colors hidden',
											{
												'bg-coolGray-50': pageScrolled,
											},
										)}
										role="tabpanel"
										aria-labelledby={name}
									>
										<nav aria-label={name}>
											<ul className="flex justify-center items-center gap-2">
												{menu &&
													menu.map((menuItem, index) => {
														const { title, columns } = menuItem;
														return <MenuDropdown title={title} columns={columns} key={index} />;
													})}
												<li>
													{cta && (
														<a
															href={cta.href}
															target={cta.newTab ? '_blank' : null}
															rel={cta.newTab ? 'noreferrer noopener' : null}
															className="inline-flex items-center gap-2 type-button border rounded-[4px] px-4 py-2 hover:bg-black hover:text-pureWhite transition-colors duration-300"
														>
															<span>{cta.label}</span>
														</a>
													)}
												</li>
											</ul>
										</nav>
									</div>
								</div>
							))}
						</div>
						<div className="sm:hidden h-[59px] min-w-[136px]">
							{showLanguageToggle && <LanguageDropdown />}
							{ABOUT_DROPDOWN_CONTENT && (
								<MenuDropdown
									title={ABOUT_DROPDOWN_CONTENT.title}
									columns={[ABOUT_DROPDOWN_CONTENT.dropdown]}
									align={'right'}
								/>
							)}
						</div>
						<button className="lg:hidden p-3 my-2" onClick={() => setBurgerOpen(!burgerOpen)}>
							<Icon icon={burgerOpen ? 'close' : 'burger'} className="w-6 h-6" />
						</button>
					</>
				)}
			</div>
			{!hideHeaderNav && (
				<div
					className={classNames(
						'lg:hidden border-b border-b-black bg-coolGray-50 text-center overflow-auto text-[0px] transition-all duration-500',
						{
							'max-h-[calc(100vh-64px)] opacity-100': burgerOpen,
							'max-h-0 opacity-0': !burgerOpen,
						},
					)}
				>
					<div className="py-6">
						{MENU_CONTENT[activeTab].cta && (
							<HexagonalCTA
								className="w-9-col"
								text={MENU_CONTENT[activeTab].cta.label}
								href={MENU_CONTENT[activeTab].cta.href}
								noArrow={true}
							/>
						)}
						<nav
							className={classNames('w-9-col mx-auto', { 'mt-12': MENU_CONTENT[activeTab].cta })}
						>
							<ul className="flex flex-col lg:flex-row justify-center items-center lg:gap-2">
								{MENU_CONTENT.map((menuItem, index) => {
									const { label, href, mobileMenu } = menuItem;
									return <MobileMenuItem label={label} href={href} menu={mobileMenu} key={index} />;
								})}
								{ABOUT_DROPDOWN_CONTENT && (
									<MobileMenuItem
										label={ABOUT_DROPDOWN_CONTENT.title}
										menu={[ABOUT_DROPDOWN_CONTENT.dropdown]}
									/>
								)}
								{showLanguageToggle && <MobileLanguageDropdown />}
							</ul>
						</nav>
					</div>
				</div>
			)}
		</div>
	);
};

export default Menu;
