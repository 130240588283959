import React, { useEffect, useRef } from 'react';

import { COOKIEBOT_ID, COOKIEBOT_ID_CAN, COOKIEBOT_ID_CCPA, COOKIEBOT_URLS } from '~utils/consts';
import { useLanguage } from '~contexts/Language';

/**
 * Cookiebot script to be added in RootWrap
 */
export const CookiebotScript = () => {
	const instance = useRef(null);
	const { language } = useLanguage();

	useEffect(() => {
		const oldDialog = document.getElementById('CybotCookiebotDialog');
		if (oldDialog) {
			delete window.Cookiebot;
			delete window.CookieConsent;
			delete window.CookieControl;
		}
		instance.current.innerHTML = '';
		const cookieBotScript = document.createElement('script');
		cookieBotScript.id = 'Cookiebot';
		cookieBotScript.src = COOKIEBOT_URLS.CONSENT;
		cookieBotScript.setAttribute('data-cbid', COOKIEBOT_ID);
		cookieBotScript.setAttribute(
			'data-georegions',
			`{'region':'US-06','cbid':'${COOKIEBOT_ID_CCPA}'},{'region':'CA','cbid':'${COOKIEBOT_ID_CAN}'}`,
		);
		cookieBotScript.setAttribute('data-culture', language);
		cookieBotScript.setAttribute('data-framework', 'TCFv2.2');
		instance.current.appendChild(cookieBotScript);
	}, [language]);

	return <div ref={instance} />;
};

export default CookiebotScript;
