import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

const VideoModal = ({ video, isVisible, onClose }) => {
	const videoEl = useRef(null);
	const handleModalClick = e => {
		if (e.target === e.currentTarget && onClose) {
			videoEl.current.pause();
			onClose();
		}
	};

	const handleKeyDown = e => {
		if (e.keyCode === 27 && onClose) {
			videoEl.current.pause();
			onClose();
		}
	};

	useEffect(() => {
		if (isVisible) {
			window.addEventListener('keydown', handleKeyDown);
			videoEl.current.currentTime = 0;
			videoEl.current.play();

			const widthBefore = document.body.offsetWidth;
			document.body.classList.add('overflow-hidden');
			document.body.style.marginRight = document.body.offsetWidth - widthBefore + 'px';
		}

		return () => {
			document.body.classList.remove('overflow-hidden');
			window.removeEventListener('keydown', handleKeyDown);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible]);

	return (
		<div
			className={classNames('fixed inset-0 bg-black/70 z-50 flex items-center', {
				hidden: !isVisible,
			})}
			onClick={handleModalClick}
			onKeyDown={handleKeyDown}
		>
			{/* <iframe
        src={`https://www.youtube-nocookie.com/embed/${
          isVisible ? videoId : null
        }?rel=0`}
        frameBorder="0"
        allowFullScreen="allowfullscreen"
        className="w-10/12 max-w-4xl aspect-video mx-auto"
      ></iframe> */}
			<video
				className="w-10/12 max-w-4xl aspect-video mx-auto"
				src={video}
				ref={videoEl}
				controls
			/>
		</div>
	);
};

export default VideoModal;
