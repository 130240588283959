import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import FancyBorder from '~globals/FancyBorder';

const WhyProduct = ({ backgroundColor, topBorderColor, topBorder, headline, blocks }) => {
	return (
		<section
			className={classNames({
				'bg-pureWhite': backgroundColor === 'white',
				'bg-pink-50': backgroundColor === 'pink',
			})}
		>
			{topBorder && topBorder !== 'no border' && (
				<FancyBorder
					type={`top-${topBorder}`}
					background={topBorderColor}
					foreground={backgroundColor}
				/>
			)}
			<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl py-16 lg:py-[120px]">
				<div className="col-start-2 lg:col-start-3 col-span-9 lg:col-span-6 type-h2 mb-12 lg:mb-0 hyphens">
					<h3>{headline}</h3>
				</div>
				<div className="col-start-2 lg:col-start-11 col-span-9 lg:col-span-13">
					{blocks.map(({ eyebrow, title, description, image }, index) => (
						<div key={index} className="lg:flex items-start mb-20 lg:mb-14 last:mb-0">
							<img src={`${image.file.url}?fm=webp`} alt="" className="block w-20" />
							<div className="lg:ml-8">
								{eyebrow && <p className="type-eyebrow opacity-60 mb-2.5">{eyebrow}</p>}
								<h4 className="type-h3 mb-5 lg:mb-3">{title}</h4>
								<p className="type-body-3 font-serif">{description.description}</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export const SectionWhyProductFragment = graphql`
	fragment SectionWhyProductFragment on ContentfulSectionWhyProduct {
		headline
		blocks {
			eyebrow
			title
			description {
				description
			}
			image {
				file {
					url
				}
			}
		}
		backgroundColor
		topBorder
		topBorderColor
	}
`;

export default WhyProduct;
