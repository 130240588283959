import React, { useState, useRef, useEffect } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import FancyBorder from '~globals/FancyBorder';
import MoreWaysStep from './step';
import useIntersectionObserver from '~hooks/useIntersectionObserver';

const MoreWays = ({ steps, moreWaysTo }) => {
	const containerRef = useRef(null);
	const leftBlockContainer = useRef(null);
	const [activeIndex, setActiveIndex] = useState(0);
	const isInView = useRef(false);
	const lastStep = useRef(null);
	const entry = useIntersectionObserver(containerRef, {
		threshold: 0.01,
	});

	const computeHeight = () => {
		if (containerRef.current) {
			const styles = getComputedStyle(lastStep.current);
			const lastStepHeight = lastStep.current.clientHeight + parseFloat(styles.marginBottom);
			const scrollPadding = Math.min(
				(window.innerHeight - leftBlockContainer.current.clientHeight) / 2,
				160,
			);

			containerRef.current.style.setProperty(
				'--right-bottom-padding',
				`${leftBlockContainer.current.clientHeight + scrollPadding - lastStepHeight}px`,
			);
			if (isInView.current && leftBlockContainer.current && window) {
				document.documentElement.style.setProperty('--scroll-padding', `${scrollPadding}px`);
			}
		}
	};

	useEffect(() => {
		computeHeight();
		window.addEventListener('resize', computeHeight);

		return () => {
			window.removeEventListener('resize', computeHeight);
		};
	}, []);

	useEffect(() => {
		if (!entry) {
			return;
		}

		if (entry.isIntersecting) {
			isInView.current = true;
			computeHeight();
			document.documentElement.classList.add('lg:snap-y', 'lg:proximity');
		} else {
			isInView.current = false;
			document.documentElement.style.removeProperty('--scroll-padding');
			document.documentElement.classList.remove('lg:snap-y', 'lg:proximity');
		}
	}, [entry]);

	if (!steps) return;

	return (
		<>
			<section ref={containerRef}>
				<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl">
					<div className="hidden lg:block lg:col-span-12">
						<div className="sticky top-0 w-full py-[var(--scroll-padding)]">
							<div className="relative flex flex-col" ref={leftBlockContainer}>
								<h3 className="text-right pr-3 type-h2 w-full">{moreWaysTo} </h3>
								<div className="w-8-col aspect-square ml-auto relative">
									{steps.map(
										({ image }, index) =>
											image && (
												<img
													key={index}
													src={`${image.file.url}?fm=webp`}
													alt=""
													className={classNames(
														'w-full h-full object-contain absolute transition-opacity',
														{
															'opacity-0': index !== activeIndex,
														},
													)}
												/>
											),
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="col-start-2 lg:col-start-auto col-span-9 lg:pb-[var(--right-bottom-padding)] lg:pt-[var(--scroll-padding)]">
						{steps.map((step, index) => (
							<MoreWaysStep
								moreWaysTo={moreWaysTo}
								step={step}
								key={index}
								active={activeIndex === index}
								handleVisible={() => setActiveIndex(index)}
								forwardedRef={lastStep}
							/>
						))}
					</div>
				</div>
			</section>
			<FancyBorder foreground="gray" background="white" type="bottom-2" />
		</>
	);
};

export const SectionMoreWaysFragment = graphql`
	fragment SectionMoreWaysFragment on ContentfulSectionMoreWays {
		moreWaysTo
		steps {
			title
			description {
				description
			}
			image {
				file {
					fileName
					url
				}
			}
			button {
				label
				href
				openInNewTab
			}
		}
	}
`;

export default MoreWays;
