import React from 'react';
import classNames from 'classnames';

const SubmenuLink = ({ label, href, icon, newTab, isMobileCta, onClick }) => {
	const newTabAttr = newTab
		? {
				target: '_blank',
				rel: 'noreferrer noopener',
		  }
		: {};
	return (
		<li
			className={classNames(
				'flex items-start max-w-[420px] gap-x-2 py-3 hover:opacity-60 lg:pr-4',
				{
					'-ml-4': isMobileCta,
				},
			)}
		>
			{icon && <img src={icon.url} className="block w-6 shrink-0" alt="" />}
			<a className="mr-1 type-body-2 cursor-pointer" href={href} onClick={onClick} {...newTabAttr}>
				{label}
			</a>
		</li>
	);
};

export default SubmenuLink;
