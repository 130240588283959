import React, { useState } from 'react';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';

const ReadMore = ({ content, more }) => {
	const [open, setOpen] = useState(false);

	return (
		<div>
			<div
				className={classNames(
					'overflow-hidden transition-all duration-300 font-serif type-body-2',
					{
						'max-h-0': more,
						'max-h-[1000px]': open,
					},
				)}
			>
				<div className="pb-4 formatted-text">
					<ReactMarkdown
						components={{
							// TODO: Fix
							// eslint-disable-next-line jsx-a11y/anchor-has-content
							a: ({ node, ...props }) => <a className="underline" {...props} />,
						}}
					>
						{content}
					</ReactMarkdown>
				</div>
			</div>
			{more && (
				<>
					<hr className={classNames('mb-3', { 'opacity-40': open })} aria-hidden="true" />
					<button
						className={classNames('flex justify-between w-full type-body-1 font-bold', {
							'opacity-40': open,
						})}
						onClick={() => setOpen(!open)}
					>
						<span>{more}</span>
						<span>{open ? '-' : '+'}</span>
					</button>
				</>
			)}
		</div>
	);
};

export default ReadMore;
