import React from 'react';

const EmailForm = () => {
	return (
		<div className="type-body-2 border rounded w-full text-center px-5 py-3 bg-emerald-100">
			Thank you! Your submission has been received!
		</div>
	);
};

export default EmailForm;
