import React, { createContext, useCallback, useContext, useState } from 'react';
import { COOKIES, setNovaCookie, getNovaCookie } from '~utils/novaCookies';

const HTML_LANGUAGE_MAP = {
	en: 'en-US',
	fr: 'fr-CA',
};
const DEFAULT_LANGUAGE = 'en';

export const LanguageContext = createContext(null);

export const useLanguage = () => useContext(LanguageContext);

const getInitialLanguage = () => {
	// during SSR build we don't have access to cookies
	if (typeof window === 'undefined') {
		return DEFAULT_LANGUAGE;
	}

	const fromCookie = getNovaCookie(COOKIES.LANGUAGE);
	if (HTML_LANGUAGE_MAP[fromCookie]) {
		return fromCookie;
	} else {
		// unforeseen language value, revert to default
		setNovaCookie(COOKIES.LANGUAGE, DEFAULT_LANGUAGE);
		return DEFAULT_LANGUAGE;
	}
};

const getUrlLanguage = () => {
	// during SSR build we don't have access to cookies
	if (typeof window === 'undefined') {
		return undefined;
	}

	const urlParams = new URLSearchParams(window.location.search);
	const urlLang = urlParams.get('lang');

	if (HTML_LANGUAGE_MAP[urlLang]) {
		return urlLang;
	}
	return undefined;
};

export const LanguageProvider = ({ children }) => {
	const [language, setLanguage] = useState(getUrlLanguage() ?? getInitialLanguage());

	const setLanguageAndCookie = useCallback(
		newValue => {
			setNovaCookie(COOKIES.LANGUAGE, newValue);
			setLanguage(newValue);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[language],
	);

	return (
		<LanguageContext.Provider
			value={{
				language,
				setLanguage: setLanguageAndCookie,
				htmlLanguage: HTML_LANGUAGE_MAP[language],
			}}
		>
			{children}
		</LanguageContext.Provider>
	);
};

export default LanguageContext;
