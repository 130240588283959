import { useEffect } from 'react';

const NOVA_EXPERIMENTS_LOADED = 'NovaExperimentsLoaded';

export function useExperimentsLoaded(onLoad) {
	useEffect(() => {
		if (window.NovaExperiments) {
			onLoad();
		} else {
			window.addEventListener(NOVA_EXPERIMENTS_LOADED, onLoad);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}

export function useVariant(experimentName, onVariantFn) {
	useExperimentsLoaded(() => {
		if (window.NovaExperiments.client.activate(experimentName) === 'VARIANT') {
			onVariantFn();
		}
	});
}

export function useFeatureFlag(featureFlagName, onEnabledFn) {
	useExperimentsLoaded(() => {
		try {
			if (window.NovaExperiments.client.getFeatureFlag(featureFlagName) === 'ENABLED') {
				onEnabledFn();
			}
		} catch (err) {
			// feature flag wasn't found
		}
	});
}
