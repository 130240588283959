import { useEffect, useState } from 'react';

export const usePageScrolled = () => {
	const threashold = 15;
	const [pageScrolled, setPageScrolled] = useState(false);

	useEffect(() => {
		if (typeof document !== `undefined`) {
			const onScroll = () => setPageScrolled(window.scrollY > threashold);

			window.addEventListener('scroll', onScroll);
			setPageScrolled(window.scrollY > threashold);

			return () => {
				window.removeEventListener('scroll', onScroll);
			};
		}
	}, []);

	return pageScrolled;
};
