import React from 'react';
import { renderRichTextBody, renderRichText } from '~utils/richText';

const PostContent = ({ disclaimer, author, content }) => {
	return (
		<section className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl pb-28 lg:pb-36">
			{disclaimer && (
				<div className="col-start-2 lg:col-start-7 col-span-9 lg:col-span-13 type-body-1 bg-coolGray-100 text-coolGray-900 py-8 px-10 rounded-lg">
					{renderRichText(disclaimer.text)}
				</div>
			)}
			{author && (
				<div className="col-start-2 lg:col-start-7 col-span-9 lg:col-span-13 flex items-center mt-10">
					{author.photo && (
						<img
							src={`${author.photo.url}?fm=webp`}
							className="w-20 h-20 object-cover block rounded-full mr-7"
							alt=""
						/>
					)}
					<div>
						{author.name && <div className="type-body-2 font-serif font-bold">{author.name}</div>}
						{author.title && (
							<div className="type-body-2 font-serif font-medium italic">{author.title}</div>
						)}
					</div>
				</div>
			)}
			<div className="formatted-text col-start-2 lg:col-start-7 col-span-9 lg:col-span-13 mt-10 type-body-2 font-serif">
				{renderRichTextBody(content)}
			</div>
		</section>
	);
};

export default PostContent;
