import React from 'react';
import { graphql } from 'gatsby';

import ProductCard from './ProductCard';

const TwoProducts = ({
	eyebrow,
	headline,
	description,
	product1Title,
	product1Subtitle,
	product1Description,
	product1Image,
	product1Cta,
	product2Title,
	product2Subtitle,
	product2Description,
	product2Image,
	product2Cta,
}) => {
	return (
		<section className="bg-pureWhite py-20">
			<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl gap-y-8 lg:gap-y-16">
				<div className="col-span-full lg:col-span-13 lg:col-start-7 text-center">
					<p className="type-eyebrow opacity-60 mb-3">{eyebrow}</p>
					<h3 className="type-h2 mb-6">{headline}</h3>
					<p className="type-body-3 font-[350]">{description.description}</p>
				</div>
				<div className="col-span-full lg:col-span-12 relative self-stretch mt-[calc(20px+14vw)] lg:mt-0">
					<ProductCard
						title={product1Title}
						subtitle={product1Subtitle}
						description={product1Description.product1Description}
						cta={product1Cta}
						icon={product1Image.file.url}
						iconBefore={true}
						hoverColor="teal"
					/>
				</div>
				<div className="col-span-full lg:col-span-12 lg:col-start-14 mb-[calc(14vw-20px)] lg:mb-0 relative self-stretch">
					<ProductCard
						title={product2Title}
						subtitle={product2Subtitle}
						description={product2Description.product2Description}
						cta={product2Cta}
						icon={product2Image.file.url}
						iconBefore={false}
						hoverColor="emerald"
					/>
				</div>
			</div>
		</section>
	);
};

export const SectionTwoProductsFragment = graphql`
	fragment SectionTwoProductsFragment on ContentfulSectionTwoProducts {
		eyebrow
		headline
		description {
			description
		}
		product1Title
		product1Subtitle
		product1Description {
			product1Description
		}
		product1Image {
			file {
				url
			}
		}
		product1Cta {
			label
			href
			openInNewTab
		}
		product2Title
		product2Subtitle
		product2Description {
			product2Description
		}
		product2Image {
			file {
				url
			}
		}
		product2Cta {
			label
			href
			openInNewTab
		}
	}
`;

export default TwoProducts;
