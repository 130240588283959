import React from 'react';
import { graphql } from 'gatsby';
import { renderRichText } from '~utils/richText';
import Accordion from '~globals/Accordion';

const Faq = ({ headline, description, questions }) => {
	return (
		<section className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl py-10">
			<div className="col-start-2 col-span-9 lg:col-span-13 lg:col-start-7">
				<h4 className="type-h3 mb-4 text-center">{headline}</h4>
			</div>
			<div className="col-start-2 col-span-9 lg:col-span-13 lg:col-start-7">
				<div className="type-body-3 font-serif formatted-text mb-10 text-center">
					{renderRichText(description)}
				</div>
				{questions.map(({ key, value }, index) => (
					<Accordion title={key} content={value} key={index} />
				))}
			</div>
		</section>
	);
};

export const SectionFaqFragment = graphql`
	fragment SectionFaqFragment on ContentfulSectionFaq {
		headline
		description {
			raw
		}
		questions {
			key
			value
		}
	}
`;

export default Faq;
