import React from 'react';
import classNames from 'classnames';
import { usePageTitle } from '~contexts/PageTitle';

import { REDIRECT_TRACKING_DELAY } from '~utils/consts';
import Icon from '~globals/Icon';

const CTA = ({ text, className, href, newTab, onClick, redirectedToCustomerEventCustomerName }) => {
	const inner = (
		<>
			<span>{text}</span>
			<Icon icon="arrow-right" width="14px" />
		</>
	);
	const classes = classNames(
		'flex items-center gap-x-2 type-button whitespace-nowrap hover:opacity-60 cursor-pointer',
		className,
	);
	const pageTitle = usePageTitle();
	const linkClickHandler = event => {
		if (redirectedToCustomerEventCustomerName) {
			event.preventDefault();
			window.NovaAnalytics.track('REDIRECTED_TO_CUSTOMER', {
				page: pageTitle,
				customerName: redirectedToCustomerEventCustomerName,
			});

			if (newTab) {
				window.open(href, '_blank');
			} else {
				setTimeout(() => {
					window.location.href = href;
				}, REDIRECT_TRACKING_DELAY);
			}
		}
	};

	if (onClick) {
		return (
			<button onClick={onClick} className={classes}>
				{inner}
			</button>
		);
	} else if (href) {
		return (
			<a
				className={classes}
				href={href}
				target={newTab ? '_blank' : null}
				rel={newTab ? 'noreferrer noopener' : null}
				onClick={linkClickHandler}
			>
				{inner}
			</a>
		);
	}
	return <div className={classes}>{inner}</div>;
};

export default CTA;
