import React from 'react';
import { graphql } from 'gatsby';

const Countries = ({ title, flags, countryNames }) => {
	return (
		<section className="bg-pureWhite py-20">
			<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl gap-y-8 lg:gap-y-16">
				<p className="col-span-9 lg:col-span-13 col-start-2 lg:col-start-7 type-h5 mb-3 text-center">
					{title}
				</p>
				<div className="col-span-9 lg:col-span-21 col-start-2 lg:col-start-3 flex flex-wrap items-center gap-x-12 justify-center">
					{flags.map((flag, index) => (
						<div className="mb-8 flex items-center" key={index}>
							<img alt="" className="h-9 w-auto block" src={`${flag.file.url}?fm=webp`} />
							<span className="ml-3 hidden lg:inline">{countryNames[index]}</span>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export const SectionCountriesFragment = graphql`
	fragment SectionCountriesFragment on ContentfulSectionCountries {
		title
		flags {
			file {
				url
				fileName
			}
		}
		countryNames
	}
`;

export default Countries;
