import React, { useEffect } from 'react';
import qs from 'qs';
import { Script } from 'gatsby';
import { Helmet } from 'react-helmet';
import useMediaQuery from '~hooks/useMediaQuery';
import { LanguageProvider } from '~contexts/Language';
import { ConsentProvider } from '~contexts/Consent';

import '~styles/global.css';

import { NOVA_ANALYTICS_URL, GTM_CONTAINER_ID, GA_MEASUREMENT_ID, ENV } from '~utils/consts';
import { setNovaCookie, getNovaCookie, COOKIES } from '~utils/novaCookies';
import { shallowMergeUrlParams, getUtmParameters } from '~utils/urls';

const FAVICON_DIMENSIONS = [
	16, // Standard for most desktop browsers
	32, // Standard for most desktop browsers
	48, // Google Search Console Guidelines
	96, // Google Search Console Guidelines
	128, // Chrome Web Store icon & Small Windows 8 Star Screen Icon*
	180, // iOS preferred
	192, // Google Developer Web App Manifest Recommendation
];

const RootWrap = ({ children }) => {
	const isDarkTheme = useMediaQuery('(prefers-color-scheme: dark)');
	const faviconColor = isDarkTheme ? 'white' : 'black';

	useEffect(() => {
		// pull UTM parameters and store them on the UTM_INFO cookie
		const utmParameters = getUtmParameters();
		// merge with previous values and store in cookie for later use
		const existing = qs.parse(getNovaCookie(COOKIES.UTM_INFO));
		const mergedParams = shallowMergeUrlParams(existing, utmParameters);
		const asString = qs.stringify(mergedParams);
		if (asString) {
			setNovaCookie(COOKIES.UTM_INFO, asString);
		}
	}, []);

	return (
		<LanguageProvider>
			<ConsentProvider>
				<Helmet>
					{/* Set up Nova Analytics */}
					<script key="script-nova">
						{`
							var analytics = (window.NovaAnalytics = window.NovaAnalytics || []);
							analytics.calls = [];
							analytics.initialized = false;
							analytics.environment = '${ENV}';
							analytics.track = function (event, properties) {
								analytics.calls.push(['track', event, properties]);
							};
							analytics.trackPage = function (pageName, properties) {
								analytics.calls.push(['trackPage', pageName, properties]);
							};
							analytics.onConsentUpdate = function (consent) {
								analytics.calls.push(['onConsentUpdate', consent]);
							};
							analytics.onConsentUpdate = function (consent) {
								analytics.calls.push(['onConsentUpdate', consent]);
							};
							analytics.setUserProps = function (properties, options) {
								analytics.calls.push(['setUserProps', properties, options]);
							};
						`}
					</script>

					{/* Set up GA and GTM consent defaults */}
					<script key="script-ga-gtm-consent">
						{`
							window.dataLayer = window.dataLayer || []; // GA
							window.dataLayerGTM = window.dataLayerGTM || []; // GTM

							// Set up a gtag function that pushes to both GA and GTM
							function gtag() {
								dataLayer.push(arguments);
								dataLayerGTM.push(arguments);
							}
							gtag("consent", "default", {
								ad_storage: "denied",
								analytics_storage: "denied",
								functionality_storage: "granted",
								personalization_storage: "denied",
								security_storage: "granted",
								wait_for_update: 500,
							});
							gtag("set", "ads_data_redaction", true);
							gtag("set", "url_passthrough", true);
						`}
					</script>

					{/* GTM */}
					<script key="script-gtm">
						{`
							(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
							new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
							j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
							'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
							})(window,document,'script','dataLayerGTM','${GTM_CONTAINER_ID[ENV]}');
						`}
					</script>

					{/* GA */}
					<script key="script-ga">
						{`
							function gtag(){dataLayer.push(arguments);}
							gtag('js', new Date());

							gtag('config', '${GA_MEASUREMENT_ID[ENV]}');
						`}
					</script>

					<meta name="facebook-domain-verification" content="2peuknrigbsd3r8amtdrqjewuzdzkv" />

					<link rel="icon" type="image/ico" href="/favicon.ico" sizes="48x48" />
					{FAVICON_DIMENSIONS.map((size, index) => (
						<link
							key={index}
							rel="icon"
							type="image/png"
							href={`/icons/favicon-${faviconColor}-${size}.png`}
							sizes={`${size}x${size}`}
						/>
					))}
				</Helmet>
				{children}

				{/* Nova Analytics */}
				<Script src={NOVA_ANALYTICS_URL[ENV]} />
				{/* GA */}
				<Script src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID[ENV]}`} />
			</ConsentProvider>
		</LanguageProvider>
	);
};

export default RootWrap;
