import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '~globals/Layout';
import HexagonalCTA from '~globals/HexagonalCTA';
import { useLanguage } from '~contexts/Language';

const T10N_COPY = {
	en: {
		title: 'Not found',
		header: "The page you're looking for isn't here",
		body: "We're not quite sure what happened",
		cta: 'Go home',
	},
	fr: {
		title: 'Non trouvé',
		header: 'La page que vous recherchez n’a pas été trouvée',
		body: "Nous ne savons pas exactement ce qui s'est passé",
		cta: "Retourner à la page d'accueil",
	},
};

const NotFoundPage = () => {
	const { language } = useLanguage();
	return (
		<Layout>
			<Helmet>
				<title>{T10N_COPY[language].title}</title>
			</Helmet>
			<section className="pt-32">
				<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl">
					<div className="col-start-2 lg:col-start-8 col-span-9 lg:col-span-11 py-28 lg:py-48 text-center">
						<h1 className="type-h2">{T10N_COPY[language].header}</h1>
						<p className="type-body-2 font-serif mt-7 mb-10 formatted-text">
							{T10N_COPY[language].body}
						</p>
						<HexagonalCTA text={T10N_COPY[language].cta} href="/" />
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default NotFoundPage;
