import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import CTA from '~globals/CTA';
import { useExperimentsLoaded } from '~utils/useExperiments';

const Post = ({ type, post }) => (
	<a
		data-post-item
		className={`border rounded-2xl p-4 flex flex-col ${
			type === 'resources' ? 'hover:bg-blue-50' : 'hover:bg-emerald-50'
		}`}
		href={`/${type === 'resources' ? 'resources' : 'corporate-blog'}/${post.slug}`}
	>
		{post.image && (
			<img
				src={`${post.image.url}?w=400&fm=webp`}
				alt={post.image.description}
				className="w-full block mb-3 h-4.5-col md:h-2-col lg:h-2.5-col object-cover rounded-lg overflow-hidden"
				loading="lazy"
			/>
		)}
		<h5 className="w-full type-h5 mb-2.5">{post.title}</h5>
		{post.summary && <p className="type-body-1 w-full">{post.summary}</p>}
		<div className="w-full grow"></div>
		<CTA text="Read More" className="mt-3" />
	</a>
);

const PostList = ({ type, featuredLabel, featuresPosts, filterLabel }) => {
	const {
		allContentfulPostExperimentContainer: { nodes: experiments },
		allContentfulPostResources: { nodes: resourcePosts },
		allContentfulPostCorporateBlog: { nodes: corporatePosts },
		allContentfulCategoryCorporateBlog,
		allContentfulCategoryResources,
	} = useStaticQuery(graphql`
		query PostListQuery {
			allContentfulPostExperimentContainer(
				filter: { node_locale: { eq: "en-US" }, active: { eq: true } }
			) {
				nodes {
					name
					active
					control {
						... on ContentfulPostResources {
							id
							slug
						}
						... on ContentfulPostCorporateBlog {
							id
							slug
						}
					}
					variant {
						... on ContentfulPostResources {
							id
						}
						... on ContentfulPostCorporateBlog {
							id
						}
					}
				}
			}
			allContentfulPostResources(
				filter: { node_locale: { eq: "en-US" } }
				sort: { publishDate: DESC }
			) {
				nodes {
					id
					publishDate
					image {
						url
						description
					}
					summary
					slug
					title
					categories {
						slug
					}
				}
			}
			allContentfulCategoryResources(
				filter: { node_locale: { eq: "en-US" } }
				sort: { name: ASC }
			) {
				nodes {
					name
					slug
					description {
						description
					}
				}
			}
			allContentfulPostCorporateBlog(
				filter: { node_locale: { eq: "en-US" } }
				sort: { publishDate: DESC }
			) {
				nodes {
					id
					publishDate
					image {
						url
						description
					}
					summary
					slug
					title
					categories {
						slug
					}
				}
			}
			allContentfulCategoryCorporateBlog(
				filter: { node_locale: { eq: "en-US" } }
				sort: { name: ASC }
			) {
				nodes {
					name
					slug
					description {
						description
					}
				}
			}
		}
	`);

	function getPost(id) {
		return (
			resourcePosts.find(post => post.id === id) || corporatePosts.find(post => post.id === id)
		);
	}

	// Slight hack - slugs are unique and rather than remove this constraint in Contentful
	// we replace the variant's slug with control so navigation works properly
	experiments.forEach(({ control, variant }) => {
		const post = getPost(variant.id);
		post.slug = control.slug;
	});

	// These are experiment ids to filter out, defaults to all variants
	const [filterPostIds, setFilterPostIds] = useState(
		experiments.map(container => container.variant.id),
	);

	const allPosts = (type === 'resources' ? resourcePosts : corporatePosts).filter(
		post => !filterPostIds.includes(post.id),
	);

	const filters =
		type === 'resources'
			? allContentfulCategoryResources.nodes
			: allContentfulCategoryCorporateBlog.nodes;

	const splitCategory = type === 'resources';

	const [currentFilter, setCurrentFilter] = useState(() => {
		if (typeof window !== 'undefined') {
			const params = new URLSearchParams(window.location.search);
			return params.get('category');
		}
		return null;
	});

	const currentFilterObj = currentFilter
		? filters.find(filter => filter.slug === currentFilter)
		: null;
	const listClasses = 'mt-9 grid md:grid-cols-2 lg:grid-cols-3 gap-x-10 lg:gap-x-12 gap-y-14';

	useExperimentsLoaded(() => {
		const filterIds = [];

		experiments.forEach(({ control, name, variant }) => {
			if (window.NovaExperiments.client.activate(name) === 'VARIANT') {
				filterIds.push(control.id);

				// If a control post is in our features post we should replace it with the variant
				const index = featuresPosts.findIndex(post => post.id === control.id);
				featuresPosts[index] = getPost(variant.id);
			} else {
				filterIds.push(variant.id);
			}
		});

		setFilterPostIds(filterIds);
	});

	return (
		<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl py-16 lg:py-[120px] relative">
			<div className="col-start-2 lg:col-start-2 col-span-9 lg:col-span-5 mb-6 lg:mb-0 lg:sticky top-0">
				<h3 className="type-h4 mt-2 mb-12">{filterLabel}</h3>
				<ul>
					{filters.map(filter => (
						<li className="mb-3" key={filter.slug}>
							<button
								role="checkbox"
								aria-checked={filter.slug === currentFilter ? 'true' : 'false'}
								className="type-body-1 text-violet-700 bg-violet-50 rounded-3xl py-[6px] pl-3 pr-5 flex items-center"
								onClick={() => {
									filter.slug === currentFilter
										? setCurrentFilter(null)
										: setCurrentFilter(filter.slug);
								}}
							>
								<div className="border-[1.5px] border-violet-700 w-3.5 h-3.5 rounded-[50%] mr-2 flex items-center justify-center">
									{filter.slug === currentFilter && (
										<div className="bg-violet-700 w-2 h-2 rounded-[50%]" />
									)}
								</div>
								<span className="mb-[2px]">{filter.name}</span>
							</button>
						</li>
					))}
				</ul>
			</div>
			<div
				className="col-start-2 lg:col-start-8 col-span-9 lg:col-span-17 mt-14 lg:mt-0"
				data-list-wrap
			>
				{!currentFilter && (
					<>
						<h4 className="type-h3">{featuredLabel}</h4>
						<ul className={listClasses}>
							{featuresPosts.map((post, index) => (
								<Post type={type} post={post} key={index} />
							))}
						</ul>
						{splitCategory &&
							filters.map((filter, filterIndex) => (
								<React.Fragment key={filterIndex}>
									<hr className="my-20" aria-hidden="true" />
									<div className="flex items-center justify-between">
										<h4 className="type-h3">{filter.name}</h4>
										<CTA
											text="View All"
											onClick={() => {
												setCurrentFilter(filter.slug);
												setTimeout(() => document.querySelector('[data-list-wrap]').focus());
											}}
										/>
									</div>
									<ul className={listClasses}>
										{allPosts
											.filter(
												post =>
													post.categories &&
													post.categories.some(category => category.slug === filter.slug),
											)
											.slice(0, 3)
											.map((post, index) => (
												<Post type={type} post={post} key={index} />
											))}
									</ul>
								</React.Fragment>
							))}
						{!splitCategory && (
							<>
								<hr className="my-20" aria-hidden="true" />
								<ul className={listClasses}>
									{allPosts
										.filter(
											post => !featuresPosts.some(featuresPost => featuresPost.slug === post.slug),
										)
										.map((post, index) => (
											<Post type={type} post={post} key={index} />
										))}
								</ul>
							</>
						)}
					</>
				)}
				{currentFilter && currentFilterObj && (
					<>
						<h4 className="type-h3">{currentFilterObj.name}</h4>
						{currentFilterObj.description && (
							<p className="type-body-3 font-serif mt-4 mb-10">
								{currentFilterObj.description.description}
							</p>
						)}
						<ul className={listClasses}>
							{allPosts
								.filter(
									post =>
										post.categories &&
										post.categories.some(category => category.slug === currentFilter),
								)
								.map((post, index) => (
									<Post type={type} post={post} key={index} />
								))}
						</ul>
					</>
				)}
			</div>
		</div>
	);
};

export const SectionPostListFragment = graphql`
	fragment SectionPostListFragment on ContentfulSectionPostList {
		type
		featuredLabel
		featuresPosts {
			... on ContentfulPostCorporateBlog {
				id
				slug
				title
				image {
					url
					description
				}
				summary
			}
			... on ContentfulPostResources {
				id
				slug
				title
				image {
					url
					description
				}
				summary
			}
		}
		filterLabel
	}
`;

export default PostList;
