import React from 'react';
import { graphql } from 'gatsby';
import ReadMore from '~globals/ReadMore';
import FancyBorder from '../../globals/FancyBorder';

const DataInfo = ({ headline, moreLabel, blocks, data }) => {
	return (
		<section>
			<FancyBorder type="top-2" foreground="blue-50" background="white" />
			<div className="bg-blue-50 py-20">
				<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl gap-y-8 lg:gap-y-16">
					<div className="col-span-9 col-start-2 lg:col-span-12 lg:col-start-8 text-center">
						<h3 className="type-h2 mb-6">{headline}</h3>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-3 gap-9 lg:gap-1-col col-start-2 lg:col-start-3 col-span-9 lg:col-span-21">
						{data.map(({ title, description, image }, index) => (
							<div className="text-center" key={index}>
								{image && (
									<img
										className="inline-block relative z-10 h-40 mb-4"
										src={`${image.file.url}?fm=webp`}
										alt=""
									/>
								)}
								<p className="type-numbers-lg">{title}</p>
								<p className="type-body-2 font-serif mt-3">{description.description}</p>
							</div>
						))}
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2 col-start-2 lg:col-start-3 col-span-9 lg:col-span-21 gap-y-[50px] lg:gap-y-[100px] gap-x-1-col mt-20 lg:mt-0">
						{blocks.map(({ key, value }, index) => (
							<div key={index}>
								<h4 className="type-h4 font-bold mb-5">{key}</h4>
								<ReadMore content={value} more={moreLabel} />
							</div>
						))}
					</div>
				</div>
			</div>
			<FancyBorder foreground="blue-50" background="white" />
		</section>
	);
};

export const SectionDataInfoFragment = graphql`
	fragment SectionDataInfoFragment on ContentfulSectionDataInfo {
		headline
		data {
			image {
				file {
					fileName
					url
				}
			}
			title
			description {
				description
			}
		}
		moreLabel
		blocks {
			key
			value
		}
	}
`;

export default DataInfo;
