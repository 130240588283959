import React from 'react';
import { graphql } from 'gatsby';
import { renderRichTextBody } from '~utils/richText';

const PostBody = ({ content }) => {
	return (
		<section className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl">
			<div className="formatted-text col-start-2 lg:col-start-7 col-span-9 lg:col-span-13 type-body-2 font-serif pt-20 lg:pt-32 pb-28 lg:pb-36">
				{renderRichTextBody(content)}
			</div>
		</section>
	);
};

export const SectionPostBodyFragment = graphql`
	fragment SectionPostBodyFragment on ContentfulSectionPostBody {
		content {
			raw
			references {
				__typename
				... on ContentfulAsset {
					id
					url
					contentful_id
					title
				}
				... on ContentfulBodyWidgetDefault {
					contentful_id
					eyebrow
					headline
					body {
						raw
					}
					widgetColor
					form
					cta {
						href
						label
						openInNewTab
					}
					companyLogo {
						url
					}
				}
			}
		}
	}
`;

export default PostBody;
