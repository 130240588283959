import React, { useEffect } from 'react';

import { DEFAULT_GTM_CONSENTS, USERLEAP_ID, COOKIEBOT_GTM_TYPE_MAPPING, ENV } from '~utils/consts';
import { onConsentUpdate } from '~utils/novaCookies';
import { getUtmParameters } from '~utils/urls';
import { useConsent } from '~contexts/Consent';

/**
 * Contains the logic for handling when Cookiebot consent is ready.
 * Should be included on all pages. When used inside ConsentProvider or
 * inside RootWrap it gets rendered twice in parallel, so it should be
 * included in Layout to cover all pages and avoid double rendering.
 */
const ConsentListener = () => {
	const { setIsConsentReady } = useConsent();

	useEffect(() => {
		window.addEventListener('CookiebotOnConsentReady', () => {
			// Read what consents have been set by the user
			const consent = window.Cookiebot.consent;

			// Make a call to update Google Consent mode
			function gtm() {
				window.dataLayerGTM.push(arguments);
			}

			const updatedGoogleConsents = { ...DEFAULT_GTM_CONSENTS };

			Object.entries(consent).forEach(([cookieBotConsentType, isGranted]) => {
				const googleType = COOKIEBOT_GTM_TYPE_MAPPING[cookieBotConsentType];
				updatedGoogleConsents[googleType] = isGranted ? 'granted' : 'denied';
			});

			gtm('consent', 'update', updatedGoogleConsents);

			if (updatedGoogleConsents.ad_storage === 'granted') {
				gtm('set', 'ads_data_redaction', false);
			}

			// Send the event JUST to GTM
			window.dataLayerGTM.push({
				event: 'Cookie Consent Ready',
				appName: 'cms',
				category: 'Consumer',
				ccpaApplies: window.Cookiebot.regulations.ccpaApplies,
				gdprApplies: window.Cookiebot.regulations.gdprApplies,
				marketing: consent.marketing,
				preferences: consent.preferences,
				statistics: consent.statistics,
			});

			// TODO: remove the try/catch wrapper once the `client-analytics` update
			// to expose an `onConsentUpdate` method is deployed.
			try {
				window.NovaAnalytics.onConsentUpdate(consent);
				onConsentUpdate(consent);
			} catch {
				// no-op
			}

			window.NovaAnalytics.track('COOKIE_CONSENT_READY', {
				ccpaApplies: window.Cookiebot.regulations.ccpaApplies,
				gdprApplies: window.Cookiebot.regulations.gdprApplies,
				marketing: consent.marketing,
				preferences: consent.preferences,
				statistics: consent.statistics,
			});

			if (consent.statistics) {
				// Setup Sprig / UserLeap
				(function (l, e, a, p) {
					window.UserLeap = function () {
						U._queue.push(arguments);
					};
					var U = window.UserLeap;
					U.appId = a;
					U._queue = [];
					a = l.createElement('script');
					a.defer = 1;
					a.src = `${e}?id=${U.appId}`;
					p = l.getElementsByTagName('script')[0];
					p.parentNode.insertBefore(a, p);
				})(document, 'https://cdn.userleap.com/shim.js', USERLEAP_ID[ENV]);
			}

			setIsConsentReady(true);

			if (window.Cookiebot.consented) {
				// Trustpilot has its own cookies so only include if consent is granted
				const trustpilotScript = document.createElement('script');
				trustpilotScript.src =
					'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
				document.body.appendChild(trustpilotScript);
			}

			if (consent.necessary && consent.preferences) {
				const utmParameters = getUtmParameters();

				// set user properties on MixPanel
				window.NovaAnalytics.setUserProps(
					{ ...utmParameters },
					{
						FacebookPixel: { skip: true },
						GoogleAnalytics: { skip: true },
						GoogleTagManager: { skip: true },
					},
				);
			}
		});

		window.addEventListener('CookiebotOnDialogInit', () => {
			window.NovaAnalytics.track('COOKIE_CONSENT_DISPLAYED', null);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <></>;
};

export default ConsentListener;
