import React from 'react';
import { graphql } from 'gatsby';

import FancyBorder from '~globals/FancyBorder';

const Member = ({ image, title, description }) => (
	<div className="text-center">
		<img
			src={`${image.url}?fm=webp`}
			alt={image.description}
			className="w-full block mb-3"
			loading="lazy"
		/>
		<h5 className="type-h5">{title}</h5>
		<p className="type-body-2 font-serif italic">{description.description}</p>
	</div>
);
const Team = ({ headline, leadershipLabel, leadershipList, directorsLabel, directorsList }) => {
	return (
		<section>
			<FancyBorder type="top-2" background="white" foreground="gray" />
			<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl py-16 lg:py-[120px]">
				<div className="col-start-2 lg:col-start-3 col-span-9 lg:col-span-7 mb-6 lg:mb-0">
					<h3 className="type-h2">{headline}</h3>
				</div>
				<div className="col-start-2 lg:col-start-11 col-span-9 lg:col-span-13 mt-14 lg:mt-0">
					<h4 className="type-h3">{leadershipLabel}</h4>
					<ul className="mt-9 grid grid-cols-2 lg:grid-cols-3 gap-x-10 lg:gap-x-12 gap-y-14">
						{leadershipList.map(({ image, title, description }, index) => (
							<Member image={image} title={title} description={description} key={index} />
						))}
					</ul>
					<h4 className="type-h3 mt-28">{directorsLabel}</h4>
					<ul className="mt-9 grid grid-cols-2 lg:grid-cols-3 gap-x-10 lg:gap-x-12 gap-y-14">
						{directorsList.map(({ image, title, description }, index) => (
							<Member image={image} title={title} description={description} key={index} />
						))}
					</ul>
				</div>
			</div>
			<FancyBorder type="bottom-2" background="white" foreground="gray" />
		</section>
	);
};

export const SectionTeamFragment = graphql`
	fragment SectionTeamFragment on ContentfulSectionTeam {
		headline
		leadershipLabel
		leadershipList {
			image {
				url
				description
			}
			title
			description {
				description
			}
		}
		directorsLabel
		directorsList {
			image {
				url
				description
			}
			title
			description {
				description
			}
		}
	}
`;

export default Team;
