import React from 'react';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ReactMarkdown from 'react-markdown';
import WidgetDefault from '~bodyWidgets/WidgetDefault';
import WidgetCard from '~bodyWidgets/WidgetCard';

export const renderLongText = content => {
	return (
		<ReactMarkdown
			components={{
				a: ({ node, href, ...props }) => {
					const externalAttrs = href.startsWith('http')
						? {
								target: '_blank',
								rel: 'noopener noreferrer',
						  }
						: {};
					// TODO: Fix
					// eslint-disable-next-line jsx-a11y/anchor-has-content
					return <a className="underline" href={href} {...externalAttrs} {...props} />;
				},
			}}
		>
			{content}
		</ReactMarkdown>
	);
};

export const renderRichText = content => {
	const options = {
		renderNode: {
			[INLINES.HYPERLINK]: ({ data }, children) => {
				const externalAttrs = data.uri.startsWith('http')
					? {
							target: '_blank',
							rel: 'noopener noreferrer',
					  }
					: {};
				return (
					<a href={data.uri} {...externalAttrs} className="underline">
						{children}
					</a>
				);
			},
			[BLOCKS.PARAGRAPH]: (node, children) => {
				return <p className="mb-4">{children}</p>;
			},
		},
	};

	return documentToReactComponents(JSON.parse(content.raw), options);
};

export const renderRichTextBody = content => {
	const options = {
		renderNode: {
			[INLINES.HYPERLINK]: ({ data }, children) => {
				const externalAttrs = data.uri.startsWith('http')
					? {
							target: '_blank',
							rel: 'noopener noreferrer',
					  }
					: {};
				return (
					<a href={data.uri} {...externalAttrs} className="underline">
						{children}
					</a>
				);
			},
			[BLOCKS.HEADING_2]: (node, children) => {
				return <h2 className="type-h2 mt-12 mb-6">{children}</h2>;
			},
			[BLOCKS.HEADING_3]: (node, children) => {
				return <h3 className="type-h3 mt-10 mb-5">{children}</h3>;
			},
			[BLOCKS.HEADING_4]: (node, children) => {
				return <h4 className="type-h4 mt-8 mb-4">{children}</h4>;
			},
			[BLOCKS.HEADING_5]: (node, children) => {
				return <h5 className="type-h5 mt-6 mb-4">{children}</h5>;
			},
			[BLOCKS.HEADING_6]: (node, children) => {
				return <h6 className="type-h6 mt-4 mb-3">{children}</h6>;
			},
			[BLOCKS.EMBEDDED_ENTRY]: node => {
				const block = content.references.find(
					elem => elem.contentful_id === node.data.target.sys.id,
				);
				if (!block) {
					return null;
				}
				switch (block.__typename) {
					case 'ContentfulBodyWidgetDefault':
						return <WidgetDefault {...block} />;
					case 'ContentfulBodyWidgetCreditCard':
						return <WidgetCard {...block} />;
					default:
						return null;
				}
			},
			[BLOCKS.EMBEDDED_ASSET]: (node, children) => {
				const image = content.references.find(
					elem => elem.contentful_id === node.data.target.sys.id,
				);
				return (
					<img className="w-full block my-10" alt={image.title} src={`${image.url}?fm=webp`} />
				);
			},
		},
	};

	return documentToReactComponents(JSON.parse(content.raw), options);
};
