import React from 'react';
import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { renderLongText } from '~utils/richText';

import Icon from '~globals/Icon';
import EmailForm from '~globals/EmailForm';
import { PAGE_NAVIGATION_REGION } from '~utils/consts';
import { useLanguage } from '~contexts/Language';

import './styles.css';

const EmailCaptureSuccess = (
	<div className="type-body-2 flex pt-6 text-pureWhite items-center gap-1">
		<Icon icon="check-circle" width="30px" />
		Thanks for subscribing!
	</div>
);

const Footer = ({ parentGroup, navigationRegion }) => {
	const { language } = useLanguage();

	const data = useStaticQuery(graphql`
		fragment MenuItem on ContentfulMenuItem {
			label
			href
			newTab
		}

		fragment contentfulGlobalSettings on ContentfulGlobalSettings {
			tabs {
				href
				name
			}
			tabsUk {
				href
				name
			}
			tabsUae {
				href
				name
			}
			tabsCan {
				href
				name
			}
			footerColumn1Title
			footerColumn2Title
			footerColumn3Title
			footerColumn1Links {
				...MenuItem
			}
			footerColumn2LinksLeft {
				...MenuItem
			}
			footerColumn2LinksRight {
				...MenuItem
			}
			footerColumn3Links {
				...MenuItem
			}
			footerCopyright
			footerLegalLinks {
				key
				value
			}
			emailCapture {
				emailCapture
			}

			# UK
			footerColumn1TitleUk
			footerColumn1LinksUk {
				...MenuItem
			}
			# @LOCALE: There's only one column of footer links for UK,
			# To change that, uncomment the following lines
			# footerColumn2TitleUk
			# footerColumn2LinksLeftUk {
			#   ...MenuItem
			# }
			# footerColumn2LinksRightUk {
			#   ...MenuItem
			# }
			footerDisclaimerUk {
				footerDisclaimerUk
			}
			footerCopyrightUk
			footerLegalLinksUk {
				key
				value
			}

			# UAE
			footerColumn1TitleUae
			footerColumn1LinksUae {
				...MenuItem
			}
			footerColumn2TitleUae
			footerColumn2LinksLeftUae {
				...MenuItem
			}
			footerColumn2LinksRightUae {
				...MenuItem
			}
			footerCopyrightUae
			footerLegalLinksUae {
				key
				value
			}

			# CAN
			footerColumn1TitleCan
			footerColumn1LinksCan {
				...MenuItem
			}
			# @LOCALE: There's only one column of footer links for UK,
			# To change that, uncomment the following lines
			# footerColumn2TitleCan
			# footerColumn2LinksLeftCan {
			#   ...MenuItem
			# }
			# footerColumn2LinksRightCan {
			#   ...MenuItem
			# }
			footerCopyrightCan
			footerLegalLinksCan {
				key
				value
			}
		}

		query FooterMenuQuery {
			en: contentfulGlobalSettings(node_locale: { eq: "en-US" }) {
				...contentfulGlobalSettings
			}
			fr: contentfulGlobalSettings(node_locale: { eq: "fr-CA" }) {
				...contentfulGlobalSettings
			}
		}
	`)[language ?? 'en'];

	let TABS, COLUMNS, COPYRIGHT, LEGAL_LINKS, DISCLAIMER, EMAIL_CAPTURE, oneColumnOnly;
	switch (navigationRegion) {
		case PAGE_NAVIGATION_REGION.UK:
			TABS = data.tabsUk;
			COLUMNS = [
				{ title: data.footerColumn1TitleUk, columns: [data.footerColumn1LinksUk] },
				// @LOCALE: There's only one column of footer links for UK,
				// To change that, uncomment the following lines
				// {title: data.footerColumn2TitleUk, columns: [data.footerColumn2LinksLeftUk, data.footerColumn2LinksRightUk]},
				// {title: data.footerColumn3Title, columns: [data.footerColumn3Links]},
			];
			// @LOCALE: There's only one column of footer links for UK,
			// To change that, remove the following line
			oneColumnOnly = true;
			DISCLAIMER = data.footerDisclaimerUk.footerDisclaimerUk;
			COPYRIGHT = data.footerCopyrightUk;
			LEGAL_LINKS = data.footerLegalLinksUk;
			break;
		case PAGE_NAVIGATION_REGION.UAE:
			TABS = data.tabsUae;
			COLUMNS = [
				{ title: data.footerColumn1TitleUae, columns: [data.footerColumn1LinksUae] },
				{
					title: data.footerColumn2TitleUae,
					columns: [data.footerColumn2LinksLeftUae, data.footerColumn2LinksRightUae],
				},
				{ title: data.footerColumn3Title, columns: [data.footerColumn3Links] },
			];
			COPYRIGHT = data.footerCopyrightUae;
			LEGAL_LINKS = data.footerLegalLinksUae;
			break;
		case PAGE_NAVIGATION_REGION.CAN:
			TABS = data.tabsCan;
			COLUMNS = [
				{ title: data.footerColumn1TitleCan, columns: [data.footerColumn1LinksCan] },
				// @LOCALE: There's only one column of footer links for Canada,
				// To change that, uncomment the following lines
				// {title: data.footerColumn2TitleCan, columns: [data.footerColumn2LinksLeftCan, data.footerColumn2LinksRightCan]},
				// {title: data.footerColumn3Title, columns: [data.footerColumn3Links]},
			];
			// @LOCALE: There's only one column of footer links for Canada,
			// To change that, remove the following line
			oneColumnOnly = true;
			COPYRIGHT = data.footerCopyrightCan;
			LEGAL_LINKS = data.footerLegalLinksCan;
			break;
		default:
			TABS = data.tabs;
			COLUMNS = [
				{ title: data.footerColumn1Title, columns: [data.footerColumn1Links] },
				{
					title: data.footerColumn2Title,
					columns: [data.footerColumn2LinksLeft, data.footerColumn2LinksRight],
				},
				{ title: data.footerColumn3Title, columns: [data.footerColumn3Links] },
			];
			COPYRIGHT = data.footerCopyright;
			LEGAL_LINKS = data.footerLegalLinks;
			EMAIL_CAPTURE = data.emailCapture.emailCapture;
	}
	const activeTab = parentGroup ? TABS.findIndex(tab => tab.name === parentGroup) : 0;

	return (
		<footer className="bg-black text-pureWhite py-16 overflow-hidden">
			<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl gap-y-16 lg:gap-x-1-col">
				<div className="lg:col-span-8 lg:col-start-2 col-span-9 col-start-2">
					<a href={TABS[activeTab].href} title="Nova Credit">
						<Icon icon="logo-stacked" className="w-full h-auto max-w-[149px]" />
					</a>
					{EMAIL_CAPTURE && (
						<div className="footer-email-capture">
							<div className="col-span-9 lg:col-span-6 col-start-2">
								<div className="mt-4 formatted-text" id="email-capture-description">
									{renderLongText(EMAIL_CAPTURE)}
								</div>
							</div>
							<div className="text-coolGray-900 col-span-9 lg:col-span-9 col-start-2 lg:col-start-13">
								<EmailForm
									customFormSucceed={EmailCaptureSuccess}
									ariaLabelledBy="email-capture-description"
									analyticsLocation="Footer Email Form"
								/>
							</div>
						</div>
					)}
				</div>
				<div
					className={classNames('col-start-2 col-span-9 grid gap-y-20 lg:gap-x-1-col', {
						'lg:col-start-10 lg:col-span-15 lg:grid-cols-15': !oneColumnOnly,
						'lg:col-start-21 lg:col-span-4 lg:grid-cols-4': oneColumnOnly,
					})}
				>
					{COLUMNS.map(({ title, columns }, index) => (
						<div
							key={index}
							className={classNames(
								columns && columns.length === 1 ? 'lg:col-span-4' : 'lg:col-span-7',
							)}
						>
							<h4 className="type-h6">{title}</h4>
							<hr className="h-px border-0 bg-coolGray-50 mt-2 mb-4" aria-hidden="true" />
							<div className="flex gap-x-1-col">
								{columns &&
									columns.map((col, index) => (
										<div className="lg:w-3-col" key={index}>
											{col.map(({ href, label, newTab }, index) => (
												<a
													href={href}
													key={index}
													target={newTab ? '_blank' : null}
													rel={newTab ? 'noreferrer noopener' : null}
													className="block my-4 type-body-1 hover:opacity-60"
												>
													{label}
												</a>
											))}
										</div>
									))}
							</div>
						</div>
					))}
				</div>
				{DISCLAIMER && (
					<div className="col-start-2 col-span-9 max-w-xl lg:col-start-2 lg:col-span-23 type-body-1 font-serif text-coolGray-50">
						{DISCLAIMER}
					</div>
				)}
				<div className="col-start-2 col-span-9 lg:col-start-2 lg:col-span-23 border-t lg:mt-12 border-coolGray-50 flex flex-col lg:flex-row justify-between items-start lg:items-center pt-4 gap-y-9">
					<div className="flex gap-x-1 lg:gap-x-2 order-last lg:order-none">
						<a
							href="https://www.instagram.com/novacredit/"
							target="_blank"
							rel="noreferrer noopener"
							className="p-[6px] hover:opacity-60"
						>
							<Icon icon="instagram" width="32" />
						</a>
						<a
							href="https://twitter.com/nova_credit"
							target="_blank"
							rel="noreferrer noopener"
							className="p-[6px] hover:opacity-60"
						>
							<Icon icon="twitter" width="32" />
						</a>
						<a
							href="https://www.linkedin.com/company/nova-credit-inc./"
							target="_blank"
							rel="noreferrer noopener"
							className="p-[6px] hover:opacity-60"
						>
							<Icon icon="linkedin" width="32" />
						</a>
						<a
							href="https://www.facebook.com/novacredit"
							target="_blank"
							rel="noreferrer noopener"
							className="p-[6px] hover:opacity-60"
						>
							<Icon icon="facebook" width="32" />
						</a>
					</div>
					<div className="type-body-1 font-serif text-coolGray-50 order-first lg:order-none">
						{COPYRIGHT}
					</div>
					<nav>
						<ul className="flex flex-col lg:flex-row gap-x-8 gap-y-5 type-body-1 font-serif text-coolGray-50">
							{LEGAL_LINKS.map(link => (
								<li key={link.key}>
									<a href={link.value}>{link.key}</a>
								</li>
							))}
						</ul>
					</nav>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
