import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import HexagonalCTA from '~globals/HexagonalCTA';
import FancyBorder from '~globals/FancyBorder';
import CTA from '~globals/CTA';

const Resources = ({ background, topBorder, cta, headline, resources }) => {
	return (
		<section
			className={classNames({
				'bg-pureWhite': background === 'white',
			})}
		>
			{topBorder && (
				<FancyBorder
					type={`top-${topBorder}`}
					background={background === 'white' ? 'gray' : 'white'}
					foreground={background}
				/>
			)}
			<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl py-16 lg:py-[120px]">
				<div className="col-start-2 lg:col-start-3 col-span-9 lg:col-span-6 mb-6 lg:mb-0">
					<h3 className="type-h2 hyphens">{headline}</h3>
					{cta && (
						<HexagonalCTA
							href={cta.href}
							className="mt-10"
							text={cta.label}
							newTab={cta.openInNewTab}
						/>
					)}
				</div>
				<div className="lg:col-start-11 lg:col-span-13 mt-14 lg:mt-0 col-span-full">
					<div className="flex lg:flex-col gap-x-1-col w-[calc(100%+18px)] -ml-2 lg:ml-0 lg:w-full overflow-auto snap-x before:content-[''] before:w-[9px] lg:before:w-8 before:shrink-0 after:content-[''] after:w-[9px] lg:after:w-8 after:shrink-0">
						{resources.map(({ title, button, image }, index) => (
							<a
								href={button.href}
								target={button.openInNewTab ? '_blank' : null}
								rel={button.openInNewTab ? 'noreferrer noopener' : null}
								key={index}
								className="snap-center w-9-col lg:w-full shrink-0 lg:flex p-4 items-start border rounded-2xl lg:mb-10 lg:last:mb-0 hover:bg-blue-50 group first:-ml-1-col lg:first:ml-0 last:-mr-1-col lg:last:mr-0"
							>
								<div className="w-full lg:w-[42.4%] block rounded-lg overflow-hidden mr-1-col aspect-[5/3] bg-blue-50">
									{image && (
										<img
											className="w-full h-full object-cover saturate-0 mix-blend-multiply group-hover:saturate-100 group-hover:mix-blend-normal"
											src={image.file.url}
											alt={image.file.fileName}
											loading="lazy"
										/>
									)}
								</div>
								<div className="lg:w-[53.6%] type-h5 mt-5 lg:mt-0">
									<p className="mb-6">{title}</p>
									{button && <CTA text={button.label} />}
								</div>
							</a>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export const SectionResourcesFragment = graphql`
	fragment SectionResourcesFragment on ContentfulSectionResources {
		headline
		cta {
			label
			href
			openInNewTab
		}
		resources {
			title
			button {
				label
				href
				openInNewTab
			}
			image {
				file {
					url
					fileName
				}
			}
		}
		background
		topBorder
	}
`;

export default Resources;
