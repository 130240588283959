import React, { useState } from 'react';
import classNames from 'classnames';
import { renderLongText } from '~utils/richText';

import CTA from '~globals/CTA';

const Accordion = ({ content, title, cta }) => {
	const [open, setOpen] = useState(false);

	return (
		<div>
			<button
				className="flex justify-between w-full type-h4 font-semibold mt-6 mb-2.5"
				onClick={() => setOpen(!open)}
			>
				<span className="text-left">{title}</span>
				<svg
					className={classNames('mt-2 transition-all duration-300', { 'rotate-180': open })}
					width="13"
					height="7"
					viewBox="0 0 13 7"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M11.2899 0.289818L6.99994 4.58982L2.69994 0.289818C2.51258 0.103567 2.25912 -0.000976562 1.99494 -0.000976562C1.73075 -0.000976562 1.4773 0.103567 1.28994 0.289818C1.19621 0.382781 1.12182 0.493383 1.07105 0.615242C1.02028 0.737101 0.994141 0.867805 0.994141 0.999817C0.994141 1.13183 1.02028 1.26253 1.07105 1.38439C1.12182 1.50625 1.19621 1.61685 1.28994 1.70982L5.57994 5.99982C5.76569 6.18577 5.98626 6.33329 6.22906 6.43394C6.47185 6.53459 6.73211 6.58639 6.99494 6.58639C7.25777 6.58639 7.51802 6.53459 7.76082 6.43394C8.00362 6.33329 8.22419 6.18577 8.40994 5.99982L12.6999 1.70982C12.7937 1.61685 12.8681 1.50625 12.9188 1.38439C12.9696 1.26253 12.9957 1.13183 12.9957 0.999817C12.9957 0.867805 12.9696 0.737101 12.9188 0.615242C12.8681 0.493383 12.7937 0.382781 12.6999 0.289818C12.5126 0.103567 12.2591 -0.000976562 11.9949 -0.000976562C11.7308 -0.000976562 11.4773 0.103567 11.2899 0.289818Z"
						fill="#0F0B09"
					/>
				</svg>
			</button>
			<div
				className={classNames(
					'type-body-1 overflow-hidden transition-all duration-300 font-serif',
					{
						'max-h-0': !open,
						'max-h-[1000px]': open,
					},
				)}
			>
				<div className={`formatted-text ${cta ? 'mb-6' : 'mb-7'}`}>{renderLongText(content)}</div>
				{cta && <CTA className="mb-9" href={cta.href} newTab={cta.openInNewTab} text={cta.label} />}
			</div>
			<hr className="" aria-hidden="true" />
		</div>
	);
};

export default Accordion;
