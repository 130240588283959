import React from 'react';
import classNames from 'classnames';

const COLORS = {
	gray: '#F5F5FA', // same us gray-50
	'gray-50': '#F5F5FA',
	'gray-100': '#ECEFF8',
	'gray-300': '#D0D7E7',
	'blue-50': '#E5F3FF',
	blue: '#BFE0FF',
	pink: '#FBE6FE',
	violet: '#BFBFFF',
	white: '#FBFBFD',
	green: '#E9FEE7',
};

const DIVIDER = {
	// 2 curves: up, down
	'top-1': {
		path: 'm1358.2 40c-14.3 0-27.6-7.6-34.8-20s-20.4-20-34.7-20h-1138.6c-14.3 0-27.5 7.6-34.7 19.9-7.2 12.4-20.3 20-34.6 20h-80.8v40.1h1440v-40.1z',
		height: 70,
	},
	// 5 curves: down, up, up, down, down
	'top-2': {
		path: 'M1440 106.3v-14c0-6.8-5.5-12.3-12.3-12.3-4.4 0-8.4-2.3-10.6-6.1l-5.7-9.9c-5.7-9.9-16.3-16-27.8-16l-120 .1c-15.3 0-29.4-8.1-37-21.4l-3.1-5.4c-7.6-13.2-21.8-21.4-37-21.4l-115.2.1h-.1-.1L939.7.1c-15.2 0-29.3 8.1-37 21.3l-1.3 2.3c-7.6 13.2-21.7 21.3-37 21.3h-58.2c-12.5 0-24 6.7-30.3 17.5-6.2 10.8-17.7 17.5-30.2 17.5H376.5c-15.2 0-29.3-8.1-36.9-21.2l-2.7-4.5C329.3 41.1 315.2 33 300 33l-106.3.1h-.1H41.9s-.1 0-.1-.1l-.1-.1L0 33v87h1440v-13.7z',
		height: 110,
	},
	// 5 curves: up, down, up, down, down
	'top-3': {
		path: 'M1407.5 86h-87.7c-10 0-19.3-5.3-24.3-14s-14.3-14-24.3-14l-257.6.2c-.1 0-.1 0-.2-.1 0-.1-.1-.1-.2-.1l-232 .2c-12.2 0-23.6-6.5-29.7-17.1-6.1-10.6-17.4-17.1-29.7-17.1l-120.7.1c-15.2 0-29.2 8.1-36.9 21.2l-11.4 19.5C545.2 77.9 531.1 86 515.9 86H289.3c-15.2 0-29.3-8.1-36.9-21.2L227 21.2C219.3 8.1 205.2 0 190 0L68.6.1C56.8.1 45.9 6.4 40 16.6 34 26.7 23.1 33 11.3 33H0v87h1440V86h-32.5z',
		height: 110,
	},
	// 6 curves: down, down, up, down, down, up
	'top-4': {
		path: 'm1356.5.1c-15.2 0-29.3 8.1-37 21.3l-25 43.2c-7.6 13.2-21.7 21.3-37 21.3h-136.4c-15.3 0-29.4-8.1-37-21.4l-4.2-7.3c-7.6-13.2-21.8-21.4-37-21.4l-138.6.1c-12.9 0-24.8-6.9-31.3-18-6.4-11-18.3-17.9-31.2-17.9l-151.6.1c-15.2 0-29.3 8.1-37 21.3l-25 43.2c-7.6 13.2-21.7 21.3-37 21.3h-284.8c-12.9 0-24.8-6.9-31.3-18s-18.4-18-31.3-18l-120.4.1c-9.4 0-18-5-22.7-13s-13.4-13-22.7-13h-72.4c-1.9 0-3.7.1-5.6.4v95.6h1440v-34-85.9z',
		height: 110,
	},
	// 2 curves: down, up
	'bottom-1': {
		path: 'M81.9,40c14.3,0,27.6,7.6,34.8,20s20.4,20,34.7,20H1290c14.3,0,27.5-7.6,34.7-19.9c7.2-12.4,20.3-20,34.6-20h80.8V0H0.1v40.1L81.9,40z',
		height: 80,
	},
	// 7 curves: down, up, up, down, up, down, up
	'bottom-2': {
		path: 'M17.2 33c15.2 0 29.3 8.1 37 21.3l6.1 10.4C67.9 77.9 82 86 97.2 86h61.5c11.1 0 21.3-5.9 26.9-15.5 5.6-9.6 15.8-15.4 26.9-15.5h63.3c15.2 0 29.3-8.1 36.9-21.3l7.2-12.5C327.5 8 341.6 0 356.8-.1l91.4.1c15.2 0 29.3 8.1 37 21.3l25.2 43.4c7.6 13.2 21.7 21.3 37 21.3h210.9c15.2 0 29.3-8.1 37-21.3l2.5-4.3c7.6-13.2 21.7-21.3 36.9-21.3L942 39h.2l106.2-.1c15.3 0 29.4 8.1 37 21.3l2.5 4.3c7.6 13.2 21.7 21.3 37 21.3h164.2c9.3 0 17.8-4.9 22.5-13 4.6-8 13.2-13 22.5-13l96.4-.1c3.3 0 6.5.4 9.6 1.1V0H0v33h17.2z',
		height: 120,
	},
	// 5 curves: down, up, down, down, up
	'bottom-3': {
		path: 'M69.5 0c.1 0 .1 0 0 0 .1.1.1.1.2.1L191 0c15.2 0 29.3 8.1 37 21.2l25.4 43.5C261 77.9 275.1 86 290.3 86H519c15.2 0 29.3-8.1 37-21.3l5.4-9.3c7.6-13.2 21.7-21.3 36.9-21.3l252.3-.1s.1 0 .1.1l.1.1 179.8-.1c9.3 0 17.9 5 22.6 13 4.7 8.1 13.3 13 22.6 13h51.2c9.3 0 17.9 4.9 22.5 13 4.7 8 13.2 13 22.5 13h164c15.2 0 29.3-8.1 37-21.3l14.7-25.3c7.6-13.2 21.7-21.3 36.9-21.3h15.3V0H0h69.5z',
		height: 120,
	},
	// 6 curves: down, up, down, up, down, up
	'bottom-4': {
		path: 'M0 40.3h1.3l113.2-.1c14.3 0 27.6 7.6 34.8 20 7.2 12.4 20.5 20 34.8 20h79.5c17.8 0 34.2-9.5 43.2-24.8l17.7-30.3C333.5 9.7 349.9.3 367.7.3L479.9.2H0v40.1zM480.3.2l.1.1L584.7.2c14.3 0 27.6 7.6 34.7 20a40.1 40.1 0 0 0 34.7 20h305.6c6.1 0 11.7-3.2 14.7-8.5 3-5.2 8.6-8.5 14.7-8.5l101.9-.1c17.8 0 34.3 9.5 43.3 24.9l4.2 7.2c9 15.4 25.4 24.9 43.2 24.9h69.2c14.3 0 27.5-7.6 34.6-20 7.2-12.4 20.3-20 34.6-20l76.9-.1h43.1V0H480.3z',
		height: 81,
	},
	// 5 curves: up, down, up, up, up
	// this is currently only used in home hero
	'bottom-home-hero': {
		path: 'M1440 19.1l-76.3.1c-15.2 0-29.3 8.1-36.9 21.3l-18.7 32.3c-7.6 13.2-21.7 21.3-36.9 21.3l-143.8.1c-.1 0-.1 0-.1-.1l-.1-.1-215.3.1c-15.2 0-29.3 8.1-36.9 21.3l-4.8 8.3c-7.6 13.2-21.7 21.3-36.9 21.3l-171 .1c-15.2 0-29.3 8.1-36.9 21.3L600 209.7c-7.6 13.2-21.7 21.3-37 21.3H446.3c-15.2 0-29.3-8.1-36.9-21.2l-5.9-10.1c-7.7-13.1-21.7-21.2-37-21.2l-119.5.1h-.1-.1l-157.9.1c-15.2 0-29.2 8.1-36.9 21.2l-12 20.6C36.1 227 29.1 231 21.5 231H0v34h1440V19.1z',
		height: 265,
	},
};

const FancyBorder = ({ type = 'bottom-1', foreground = 'blue', background = 'gray' }) => {
	const divider = DIVIDER[type];
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox={`0 0 1440 ${divider.height}`}
			className={classNames('w-full h-auto', { '-mt-[18.4027vw]': type === 'bottom-home-hero' })}
			aria-hidden="true"
		>
			<rect width="1440" height={divider.height} fill={COLORS[background]} />
			<path fillRule="evenodd" clipRule="evenodd" d={divider.path} fill={COLORS[foreground]} />
		</svg>
	);
};

export default FancyBorder;
