import React from 'react';
import classNames from 'classnames';

import Icon from '~globals/Icon';
import SubmenuLink from './SubmenuLink';

const MenuDropdown = ({ className, title, columns, icon, align = 'left' }) => {
	const blurSelf = event => {
		event.currentTarget.blur();
	};

	const blurOtherTabs = () => {
		const tabs = document.querySelector('[data-main-menu-tabs]');
		if (tabs?.contains(document.activeElement)) {
			document.activeElement.blur();
		}
	};

	return (
		<li
			className={classNames(
				'group py-5 px-6 lg:flex relative cursor-pointer text-left items-center lg:hover:bg-pureWhite lg:focus-within:bg-pureWhite',
				className,
			)}
		>
			{icon && <Icon icon={icon} width="20" className="sm:hidden mr-2" />}
			<button
				onClick={blurSelf}
				onMouseEnter={blurOtherTabs}
				className="mr-1 type-nav-item-level-2 opacity-100"
			>
				{title}
			</button>
			<Icon icon="chevron-down" width="20" className="sm:hidden" />
			<ul
				className={classNames(
					'lg:hidden lg:group-focus-within:flex lg:group-hover:flex lg:absolute lg:min-w-max pt-4 pb-2 px-3 lg:px-6 lg:bg-pureWhite top-full rounded-b-xl gap-x-12 cursor-default',
					{ 'left-0': align === 'left', 'right-0': align === 'right' },
				)}
			>
				{columns &&
					columns.map((column, index) => {
						const { links, ctas, columnHeadline } = column;
						return (
							<div key={index} className="lg:pb-3">
								{columnHeadline && (
									<li className="max-w-[420px] py-3 type-eyebrow font-[550] opacity-60">
										{columnHeadline}
									</li>
								)}
								{links.map((link, index) => (
									<SubmenuLink
										key={index}
										href={link.href}
										label={link.label}
										icon={link.icon}
										newTab={link.newTab}
										onClick={link.onClick}
									/>
								))}
								{ctas && (
									<hr className="h-px bg-coolGray-500 border-0 my-2 sm:hidden" aria-hidden="true" />
								)}
								{ctas &&
									ctas.map((cta, index) => (
										<SubmenuLink
											key={index}
											href={cta.href}
											label={cta.label}
											icon={cta.icon}
											newTab={cta.newTab}
										/>
									))}
							</div>
						);
					})}
			</ul>
		</li>
	);
};

export default MenuDropdown;
