import React from 'react';
import { graphql } from 'gatsby';
import ReadMore from '~globals/ReadMore';
import classNames from 'classnames';

import { renderRichText, renderLongText } from '~utils/richText';

const Blocks = ({ column }) => (
	<>
		{column.map((accordion, index) => (
			<div key={index} className="mb-10 lg:mb-16">
				<h4 className={accordion.largeTitle ? 'type-h1 mb-2' : 'type-h4 font-bold mb-5'}>
					{accordion.title}
				</h4>
				{accordion.description && (
					<div className="font-serif type-body-2 pb-4 formatted-text">
						{renderLongText(accordion.description.description)}
					</div>
				)}
				{accordion.viewMoreLabel && accordion.viewMoreBody && (
					<ReadMore content={accordion.viewMoreBody.viewMoreBody} more={accordion.viewMoreLabel} />
				)}
				{accordion.extraData && (
					<div className="type-body-1">{renderRichText(accordion.extraData)}</div>
				)}
			</div>
		))}
	</>
);

const ThreeColumns = ({
	hasDividerOnTop,
	headline,
	column1Blocks,
	column2Blocks,
	column3Blocks,
	backgroundColor,
}) => {
	const twoColumns = !column3Blocks;
	const isGrid = twoColumns
		? column1Blocks.length === column2Blocks.length
		: column1Blocks.length === column2Blocks.length &&
		  column2Blocks.length === column3Blocks.length;
	const allItems = [];
	if (isGrid) {
		column1Blocks.forEach((item, index) => {
			allItems.push(item);
			allItems.push(column2Blocks[index]);
			if (!twoColumns) {
				allItems.push(column3Blocks[index]);
			}
		});
	}
	return (
		<section
			className={classNames({
				'bg-pureWhite': backgroundColor === 'white',
				'bg-coolGray-50': backgroundColor === 'gray-50',
				'bg-coolGray-100': backgroundColor === 'gray-100',
			})}
		>
			<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl py-16 lg:pb-12">
				{hasDividerOnTop && <hr className="col-span-23 col-start-2 mb-14" aria-hidden="true" />}
				<div className="col-start-2 lg:col-start-3 col-span-9 lg:col-span-4 mb-11 lg:mb-[76px] lg:mb-0">
					<h3 className="type-h3">{headline}</h3>
				</div>
				<div
					className={`col-start-2 lg:col-start-8 col-span-9 lg:col-span-17 grid gap-x-1-col grid-cols-1 lg:grid-cols-${
						twoColumns ? 2 : 3
					}`}
				>
					{!isGrid && (
						<div>
							<Blocks column={column1Blocks} />
						</div>
					)}
					{!isGrid && (
						<div>
							<Blocks column={column2Blocks} />
						</div>
					)}
					{!isGrid && column3Blocks && (
						<div>
							<Blocks column={column3Blocks} />
						</div>
					)}
					{isGrid && <Blocks column={allItems} />}
				</div>
			</div>
		</section>
	);
};

export const SectionThreeColumnsFragment = graphql`
	fragment SectionThreeColumnsFragment on ContentfulSectionThreeColumns {
		hasDividerOnTop
		headline
		column1Blocks {
			title
			largeTitle
			description {
				description
			}
			viewMoreLabel
			viewMoreBody {
				viewMoreBody
			}
			extraData {
				raw
			}
		}
		column2Blocks {
			title
			largeTitle
			description {
				description
			}
			viewMoreLabel
			viewMoreBody {
				viewMoreBody
			}
			extraData {
				raw
			}
		}
		column3Blocks {
			title
			largeTitle
			description {
				description
			}
			viewMoreLabel
			viewMoreBody {
				viewMoreBody
			}
			extraData {
				raw
			}
		}
		backgroundColor
	}
`;

export default ThreeColumns;
