import React from 'react';
import classNames from 'classnames';

import { REDIRECT_TRACKING_DELAY } from '~utils/consts';
import { usePageTitle } from '~contexts/PageTitle';

import './styles.css';

const HexagonalButton = ({
	children,
	className,
	href,
	type,
	newTab,
	white,
	onClick = false,
	redirectedToCustomerEventCustomerName,
	buttonType = 'secondary', // primary, secondary, tertiary
}) => {
	const pageTitle = usePageTitle();
	const linkClickHandler = event => {
		if (redirectedToCustomerEventCustomerName) {
			event.preventDefault();
			window.NovaAnalytics.track('REDIRECTED_TO_CUSTOMER', {
				page: pageTitle,
				customerName: redirectedToCustomerEventCustomerName,
			});

			if (newTab) {
				window.open(href, '_blank');
			} else {
				setTimeout(() => {
					window.location.href = href;
				}, REDIRECT_TRACKING_DELAY);
			}
		}
	};

	const isPrimary = buttonType === 'primary';

	const buttonInner = (
		<div
			className={classNames(
				'children-wrap inline-flex flex-grow justify-center items-center gap-x-2 whitespace-nowrap h-12 transition-colors duration-300',
				white && 'group-hover:bg-pureWhite group-hover:text-black border-pureWhite',
				!white && buttonType !== 'tertiary' && 'group-hover:text-pureWhite border-black',
				isPrimary && 'group:text-pureWhite',
			)}
		>
			{children}
		</div>
	);

	const wrapperClassName = classNames(
		'hex-button group inline-flex type-button items-start cursor-pointer',
		className,
		buttonType,
		buttonType === 'primary' && 'fill-black',
		buttonType === 'secondary' && 'fill-transparent hover:fill-black',
	);

	if (onClick) {
		return (
			<div className="inline-block">
				<button onClick={onClick} className={wrapperClassName}>
					{buttonInner}
				</button>
			</div>
		);
	}

	if (type) {
		return (
			<div className="inline-block">
				<button type={type} className={wrapperClassName}>
					{buttonInner}
				</button>
			</div>
		);
	}

	return (
		<>
			<div className="inline-block">
				<a
					href={href}
					target={newTab ? '_blank' : null}
					rel={newTab ? 'noreferrer noopener' : null}
					className={wrapperClassName}
					onClick={linkClickHandler}
				>
					{buttonInner}
				</a>
			</div>
		</>
	);
};

export default HexagonalButton;
