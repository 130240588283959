import classNames from 'classnames';
import React from 'react';
import Icon from '~globals/Icon';

const hoverColors = {
	gold: 'hover:bg-gold-50',
	violet: 'hover:bg-violet-50',
	blue: 'hover:bg-blue-50',
	teal: 'hover:bg-teal-50',
	rose: 'hover:bg-rose-50',
	emerald: 'hover:bg-emerald-50',
};
const imgBgColors = {
	gold: 'bg-gold-300',
	violet: 'bg-violet-300',
	blue: 'bg-blue-300',
	teal: 'bg-teal-300',
	rose: 'bg-rose-300',
	emerald: 'bg-emerald-300',
};

const CaseStudyCard = ({ color, logo, image, eyebrow, title, description, cta }) => {
	const wrapperClass = classNames(
		'w-9-col md:w-4-col lg:w-17-col shrink-0 snap-center p-1-col md:p-0.5-col lg:p-1-col rounded-2xl border grid gap-y-5 lg:grid-cols-15 group cursor-pointer first:-ml-1-col last:-mr-1-col',
		hoverColors[color],
	);
	const inner = (
		<>
			<div
				className={classNames(
					'sm:max-h-96 lg:col-span-5 aspect-[276/364] rounded-xl relative overflow-hidden',
					imgBgColors[color],
				)}
			>
				{image && (
					<img
						src={`${image.file.url}?fm=webp`}
						alt={image.description}
						className="w-full h-full object-cover saturate-0 mix-blend-multiply group-hover:saturate-100 group-hover:mix-blend-normal"
						loading="lazy"
					/>
				)}
				{logo && (
					<img
						src={`${logo.file.url}?fm=webp`}
						className="absolute left-1/2 transform -translate-x-1/2 top-9"
						alt=""
						loading="lazy"
					/>
				)}
			</div>
			<div className="lg:col-span-9 lg:col-start-7">
				<p className="type-eyebrow opacity-60 mb-2">{eyebrow}</p>
				<h4 className="type-h3">{title}</h4>
				<p className="type-body-2 font-serif mt-4">{description.description}</p>
				{cta && (
					<div className="type-button inline-flex items-center gap-x-2 py-3 mt-12">
						<span>{cta.label}</span>
						<Icon icon="arrow-right" width="14px" />
					</div>
				)}
			</div>
		</>
	);

	return cta ? (
		<a
			href={cta.href}
			target={cta.openInNewTab ? '_blank' : null}
			rel={cta.openInNewTab ? 'noreferrer noopener' : null}
			className={wrapperClass}
		>
			{inner}
		</a>
	) : (
		<div className={wrapperClass}>{inner}</div>
	);
};

export default CaseStudyCard;
