import React from 'react';
import { graphql } from 'gatsby';

import FancyBorder from '~globals/FancyBorder';

const Awards = ({ awards }) => {
	return (
		<>
			<section className="mx-auto w-container max-w-screen-xl py-10 flex items-center justify-center gap-4 lg:gap-12 sm:flex-wrap">
				{awards.map((award, index) => (
					<div className="grid items-center justify-center w-[45%] lg:w-[224px]" key={index}>
						<svg
							className="w-full row-start-1 col-start-1"
							viewBox="0 0 224 139"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							aria-hidden="true"
						>
							<path
								d="M39.761 137.119L4.188 75.506a12.777 12.777 0 0 1 .004-12.784L39.853 1.054M184.23 137.118l35.575-61.613a12.778 12.778 0 0 0-.004-12.784L184.14 1.053"
								stroke="#7BD171"
								strokeWidth="2"
								strokeLinecap="round"
							/>
							<path
								d="M48.652 137.118L12.19 74.036a9.828 9.828 0 0 1 .01-9.855L48.898 1M175.34 137.118l36.463-63.082a9.83 9.83 0 0 0-.011-9.855L175.095 1"
								stroke="#7BD171"
								strokeWidth="2"
								strokeLinecap="round"
							/>
							<path
								d="M57.498 137.118L20.174 72.54a6.88 6.88 0 0 1-.002-6.882L57.498 1m108.997 136.118l37.324-64.578a6.879 6.879 0 0 0 .001-6.882L166.495 1"
								stroke="#7BD171"
								strokeWidth="2"
								strokeLinecap="round"
							/>
						</svg>
						<h3 className="row-start-1 col-start-1 text-center px-8 type-h6 sm:text-[11px]">
							{award.key}
						</h3>
						<p className="row-start-1 col-start-1 self-end text-center px-8 font-serif text-[10px] font-medium opacity-50">
							{award.value}
						</p>
					</div>
				))}
			</section>
			<FancyBorder type="bottom-2" background="white" foreground="gray" />
		</>
	);
};

export const SectionAwardsFragment = graphql`
	fragment SectionAwardsFragment on ContentfulSectionAwards {
		awards {
			key
			value
		}
	}
`;

export default Awards;
