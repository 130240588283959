import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';

import HexagonalCTA from '~globals/HexagonalCTA';
import useIntersectionObserver from '~hooks/useIntersectionObserver';

const MoreWaysStep = ({
	moreWaysTo,
	step: { title, description, button, image },
	active,
	handleVisible,
	forwardedRef,
}) => {
	const ref = useRef(null);
	const entry = useIntersectionObserver(ref, {
		rootMargin: `-35% 0px -45% 0px`,
	});
	const isVisible = !!entry?.isIntersecting;

	useEffect(() => {
		if (isVisible && handleVisible) {
			handleVisible();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible]);

	return (
		<div
			className={classNames(
				' my-16 lg:my-[10vw] lg:first:mt-0 transition-opacity duration-300 relative snap-start',
				{
					'lg:opacity-40': !active,
				},
			)}
			ref={el => {
				forwardedRef.current = el;
				ref.current = el;
			}}
		>
			<img
				src={image ? `${image.file.url}?fm=webp` : null}
				alt={image ? image.file.fileName : null}
				className="w-7-col lg:w-5-col mb-4 lg:mb-7 lg:hidden"
			/>
			<h3 className="type-h2">
				<span className="lg:hidden">{moreWaysTo} </span>
				{title}
			</h3>
			<p className="mt-5 type-body-3 font-serif">{description.description}</p>
			{button && (
				<HexagonalCTA
					href={button.href}
					text={button.label}
					newTab={button.openInNewTab}
					className="mt-8"
				/>
			)}
		</div>
	);
};

export default MoreWaysStep;
