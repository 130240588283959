import React from 'react';
import classNames from 'classnames';

const Trustpilot = ({ backgroundColor } = { backgroundColor: 'gray-50' }) => (
	<section
		className={classNames('overflow-hidden', {
			'bg-pureWhite': backgroundColor === 'white',
			'bg-coolGray-50': backgroundColor === 'gray-50',
			'bg-coolGray-300': backgroundColor === 'gray-300',
			'bg-pink-50': backgroundColor === 'pink',
			'bg-blue-100': backgroundColor === 'blue',
		})}
	>
		<div
			className="trustpilot-widget col-start-2 col-span-9 lg:col-span-9 order-2 flex flex-wrap lg:flex-nowrap items-start mt-4 text-center gap-x-8 justify-center lg:gap-8"
			data-locale="en-US"
			data-template-id="54ad5defc6454f065c28af8b"
			data-businessunit-id="5eeaec5793ff0f0001f4bfdd"
			data-style-height="240px"
			data-style-width="80%"
			data-theme="light"
			data-review-languages="en"
			data-tags="SelectedReview"
		></div>
	</section>
);

export default Trustpilot;
