import flagAustralia from '~images/flags/australia.svg';
import flagBrazil from '~images/flags/brazil.svg';
import flagCanada from '~images/flags/canada.svg';
import flagDominicanRepublic from '~images/flags/dominican-republic.svg';
import flagIndia from '~images/flags/india.svg';
import flagKenya from '~images/flags/kenya.svg';
import flagMexico from '~images/flags/mexico.svg';
import flagNigeria from '~images/flags/nigeria.svg';
import flagUnitedKingdom from '~images/flags/united-kingdom.svg';
import flagSouthKorea from '~images/flags/south-korea.svg';
import flagSwitzerland from '~images/flags/switzerland.svg';

export const ENV = process.env.GATSBY_STAGING ? 'staging' : process.env.NODE_ENV;

export const MOBILE_SIZE = 1024;

export const REDIRECT_TRACKING_DELAY = 1000;

export const COUNTRIES = [
	{
		name: 'Australia',
		value: 'AUS',
		flag: flagAustralia,
	},
	{
		name: 'Brazil',
		value: 'BRA',
		flag: flagBrazil,
	},
	{
		name: 'Canada',
		value: 'CAN',
		flag: flagCanada,
	},
	{
		name: 'Dominican Republic',
		value: 'DOM',
		flag: flagDominicanRepublic,
	},
	{
		name: 'India',
		value: 'IND',
		flag: flagIndia,
	},
	{
		name: 'Kenya',
		value: 'KEN',
		flag: flagKenya,
	},
	{
		name: 'Mexico',
		value: 'MEX',
		flag: flagMexico,
	},
	{
		name: 'Nigeria',
		value: 'NGA',
		flag: flagNigeria,
	},
	{
		name: 'South Korea',
		value: 'KOR',
		flag: flagSouthKorea,
	},
	{
		name: 'Switzerland',
		value: 'CHE',
		flag: flagSwitzerland,
	},
	{
		name: 'United Kingdom',
		value: 'GBR',
		flag: flagUnitedKingdom,
	},
];

export const CONSUMERCORE_URLS = {
	development: 'http://localhost:8012',
	staging: 'https://hello.aphrodite.novacredit.com',
	production: 'https://hello.novacredit.com',
};

export const CONSUMER_URLS = {
	development: 'http://localhost:3333',
	staging: 'https://hello.aphrodite.novacredit.com',
	production: 'https://hello.novacredit.com',
};

export const CMS_URLS = {
	development: 'http://localhost:8001',
	staging: 'https://cms.aphrodite.novacredit.com',
	production: 'https://www.novacredit.com',
};

export const BASE_API_URLS = {
	development: 'http://localhost:8012',
	staging: 'https://cms.aphrodite.novacredit.com',
	production: 'https://novacredit.com',
};

export const GTM_CONTAINER_ID = {
	development: 'GTM-NDJGJC4',
	staging: 'GTM-55T5GLS',
	production: 'GTM-WVBQHCH',
};

export const GA_MEASUREMENT_ID = {
	development: 'G-9TWW7VRQK2',
	staging: 'G-2L8V3EP6ZK',
	production: 'UA-119692704-1',
};

export const NOVA_ANALYTICS_URL = {
	development: 'http://localhost:4434/v1/measure.js',
	staging: 'https://cms.aphrodite.novacredit.com/measure/v1/measure.js',
	production: 'https://novacredit.com/measure/v1/measure.js',
};

export const USERLEAP_ID = {
	development: 'HhMqEGSK6UN',
	staging: 'HhMqEGSK6UN',
	production: 'dPCcu9x_f-Q',
};

/**
 * We use the Cookiebot library to receive consent from our consumers to use cookies on
 * their browser. The COOKIEBOT constants below support two needs:
 *
 * 1) asking consumers for consent via a cookie banner pop-up
 * 2) rendering some dynamic content on our Cookie Policy legal pages
 *
 * See additional documentation:
 * Cookiebot SDK: https://www.cookiebot.com/en/developer/
 * Guide: https://support.cookiebot.com/hc/en-us/articles/4405978132242-Manual-Markup-Guide
 */
export const COOKIEBOT_ID = 'ee907feb-d966-409a-a6aa-42f8b2276877';
export const COOKIEBOT_ID_CCPA = '505a6b34-b62e-4ab5-aede-fecd5877ecc5';
export const COOKIEBOT_ID_CAN = '700286f3-b4cd-4609-82b9-1efd86d0978c';

export const COOKIEBOT_URLS = {
	CONSENT: 'https://consent.cookiebot.com/uc.js',
	DECLARATION: `https://consent.cookiebot.com/${COOKIEBOT_ID}/cd.js`,
};

export const DEFAULT_GTM_CONSENTS = {
	ad_storage: 'denied',
	analytics_storage: 'denied',
	functionality_storage: 'granted',
	personalization_storage: 'denied',
	security_storage: 'granted',
};

export const COOKIE_TYPES = {
	necessary: 'necessary', // this will always be true
	preferences: 'preferences',
	statistics: 'statistics',
	marketing: 'marketing',
};

export const COOKIE_TYPES_DEFAULT = {
	[COOKIE_TYPES.necessary]: true,
	[COOKIE_TYPES.preferences]: false,
	[COOKIE_TYPES.statistics]: false,
	[COOKIE_TYPES.marketing]: false,
};

export const COOKIE_TYPES_ENABLED = {
	[COOKIE_TYPES.necessary]: true,
	[COOKIE_TYPES.preferences]: true,
	[COOKIE_TYPES.statistics]: true,
	[COOKIE_TYPES.marketing]: true,
};

export const COOKIEBOT_GTM_TYPE_MAPPING = {
	[COOKIE_TYPES.marketing]: 'ad_storage',
	[COOKIE_TYPES.statistics]: 'analytics_storage',
	[COOKIE_TYPES.necessary]: 'functionality_storage',
	[COOKIE_TYPES.preferences]: 'personalization_storage',
};

export const PAGE_NAVIGATION_REGION = {
	CAN: 'CAN',
	UAE: 'UAE',
	UK: 'UK',
};
