import React from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';

import { renderLongText } from '~utils/richText';

import CTA from '~globals/CTA';
import FancyBorder from '~globals/FancyBorder';

const ThreeColumnsCentered = ({ backgroundColor, headline, blocks, topBorder, topBorderColor }) => {
	if (!blocks) blocks = [];
	return (
		<section
			className={classNames('overflow-hidden', {
				'bg-pureWhite': backgroundColor === 'white',
				'bg-coolGray-50': backgroundColor === 'gray-50',
				'bg-coolGray-300': backgroundColor === 'gray-300',
				'bg-pink-50': backgroundColor === 'pink',
				'bg-blue-100': backgroundColor === 'blue',
			})}
		>
			{topBorder && topBorder !== 'no border' && (
				<FancyBorder
					type={`top-${topBorder}`}
					background={topBorderColor}
					foreground={backgroundColor}
				/>
			)}
			<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl py-16 lg:pb-12">
				<div className="col-start-2 lg:col-start-5 col-span-9 lg:col-span-17">
					<h2 className="type-h2 text-center mb-11">{headline}</h2>
					<div className={`grid lg:grid-cols-${blocks.length} gap-x-14 gap-y-11`}>
						{blocks.map((block, index) => (
							<div key={index}>
								<h3 className="type-h6 mb-3">{block.title}</h3>
								{block.description && (
									<div className="type-body-2 font-serif mb-3 formatted-text">
										{renderLongText(block.description.description)}
									</div>
								)}
								{block.button && (
									<CTA
										href={block.button.href}
										text={block.button.label}
										newTab={block.button.openInNewTab}
									/>
								)}
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export const SectionThreeColumnsCenteredFragment = graphql`
	fragment SectionThreeColumnsCenteredFragment on ContentfulSectionThreeColumnsCentered {
		headline
		blocks {
			title
			description {
				description
			}
			button {
				label
				href
				openInNewTab
			}
		}
		backgroundColor
		topBorder
		topBorderColor
	}
`;

export default ThreeColumnsCentered;
