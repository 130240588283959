import React, { useState } from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';

import VideoModal from '~globals/VideoModal';
import Icon from '~globals/Icon';
import FancyBorder from '~globals/FancyBorder';
import Trustpilot from '~sections/Trustpilot';

const VideoModalSection = ({
	backgroundColor,
	compact,
	small,
	topBorder,
	topBorderColor,
	headline,
	modalButtonLabel,
	video,
	image,
	fallbackImageSafari,
	includeTrustpilot,
}) => {
	const [videoModalVisible, setVideoModalVisible] = useState(false);
	const handleButtonClick = () => {
		setVideoModalVisible(!videoModalVisible);
	};

	// TODO: the Safari fallback image is in place because Safari seems to have low framerate when rendering animated webp.
	// To be removed when that issue is resolved.
	const isSafari =
		typeof navigator !== 'undefined' && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	const displayImage = isSafari && fallbackImageSafari ? fallbackImageSafari : image;

	return (
		<section
			className={classNames('overflow-hidden', {
				'bg-pureWhite': backgroundColor === 'white',
				'bg-coolGray-50': backgroundColor === 'gray-50',
				'bg-coolGray-300': backgroundColor === 'gray-300',
				'bg-pink-50': backgroundColor === 'pink',
				'bg-blue-100': backgroundColor === 'blue',
			})}
		>
			{topBorder && topBorder !== 'no border' && (
				<FancyBorder
					type={`top-${topBorder}`}
					background={topBorderColor}
					foreground={backgroundColor}
				/>
			)}
			<div
				className={classNames(
					'grid lg:block grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl',
					{
						'py-20 lg:py-40': !compact,
					},
				)}
			>
				<button
					className={classNames(
						'grid items-center justify-center col-start-1 col-span-11 m-auto text-center text-pureWhite',
						{
							'lg:w-[880px]': !small,
							'lg:w-[620px]': small,
						},
					)}
					onClick={handleButtonClick}
				>
					<img
						className="row-start-1 col-start-1 w-full"
						src={`${displayImage.url}?fm=webp`}
						alt={displayImage.description}
						loading="lazy"
					/>
					<div className="row-start-1 col-start-1 w-full px-[10%]">
						<h3 className="type-h4 lg:type-h2">{headline}</h3>
						<div className="flex items-center justify-center type-button gap-x-2 mt-4 lg:mt-10">
							<Icon icon="play" width="16px" />
							<span className="mb-0.5">{modalButtonLabel}</span>
						</div>
					</div>
				</button>
			</div>
			<VideoModal video={video.url} isVisible={videoModalVisible} onClose={handleButtonClick} />

			{/* Trustpilot should be it's own section type but we ran out of ContentTypes */}
			{includeTrustpilot && (
				<div className="my-10">
					<Trustpilot />
				</div>
			)}
		</section>
	);
};

export const SectionVideoModalFragment = graphql`
	fragment SectionVideoModalFragment on ContentfulSectionVideoModal {
		headline
		video {
			url
		}
		modalButtonLabel
		video {
			url
		}
		image {
			url
			description
		}
		fallbackImageSafari {
			url
			description
		}
		backgroundColor
		compact
		small
		topBorder
		topBorderColor
		includeTrustpilot
	}
`;

export default VideoModalSection;
