import React, { useState } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import { useIsMobile } from '~hooks/useIsMobile';

import './styles.css';

const HowItWorks = ({
	firstDefault,
	headline,
	description,
	introImage = null,
	steps,
	hideIndex = false,
}) => {
	const [activeIndex, setActiveIndex] = useState(firstDefault ? 0 : -1);
	const isMobile = useIsMobile();

	return (
		<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl px pb-16 lg:py-[120px] bg-pureWhite rounded-2xl">
			<div className="relative col-start-2 lg:col-start-2 col-span-9 lg:col-span-10 mb-6 lg:mb-0">
				{!isMobile &&
					[introImage, ...steps.map(({ image }) => image)]
						.filter(n => n)
						.map((image, index) => (
							<img
								className={classNames(
									'opacity-0 transition-opacity duration-300 w-full h-full object-contain relative',
									index,
									{
										'!opacity-100 delay-100': introImage
											? activeIndex + 1 === index
											: activeIndex === index,
										'!absolute inset-0': index > 0,
									},
								)}
								src={image.file.url}
								alt=""
								key={index}
							/>
						))}
			</div>
			<div className="col-start-2 lg:col-start-14 col-span-9 lg:col-span-9 mt-10 lg:mt-0">
				<div>
					<h4 className="type-h3 mb-6">{headline}</h4>
					<p className="type-body-2 font-serif mb-12">{description.description}</p>
					{steps.map(({ title, description, image }, index) => (
						<div
							key={index}
							className={classNames('type-h5 p-5 border rounded-2xl mb-3', {
								'bg-blue-50': activeIndex === index,
							})}
						>
							<button className="flex text-left w-100" onClick={() => setActiveIndex(index)}>
								{!hideIndex && <span className="mr-4">{index + 1}.</span>}
								<p className="type-h5 flex-grow">{title}</p>
								<svg
									className="relative top-[6px] chevron"
									width="16"
									height="11"
									viewBox="0 0 16 11"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true"
								>
									<path
										d="M8.00001 10.437L0.249512 2.71799L2.34351 0.625488L8.00001 6.28199L13.6565 0.625488L15.7505 2.71799L8.00001 10.437Z"
										fill="#0F0B09"
									/>
								</svg>
							</button>
							<div
								className={classNames(
									'type-body-1 max-h-0 overflow-hidden transition-all duration-300',
									{ 'max-h-[500px]': activeIndex === index },
								)}
							>
								<p className="mt-4">{description.description}</p>
								{isMobile && (
									<img
										className="mt-5"
										src={image.file.url}
										alt={image.fileName ? image.fileName : null}
									/>
								)}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export const SectionHowItWorksFragment = graphql`
	fragment SectionHowItWorksFragment on ContentfulSectionHowItWorks {
		headline
		description {
			description
		}
		steps {
			title
			description {
				description
			}
			image {
				file {
					fileName
					url
				}
			}
		}
		introImage {
			file {
				url
				fileName
			}
		}
		firstDefault
		hideIndex
	}
`;

export default HowItWorks;
