import qs from 'qs';
import { CMS_URLS, ENV } from './consts';

export const getCurrentUrl = (path, slug) => {
	// Regex removes all trailing slashes from url
	return [CMS_URLS[ENV], path, slug].filter(Boolean).join('/').replace(/\/+$/, '');
};

/**
 * Shallow merges `obj` into `base`. Appends values to array when a same key is present in both objects.
 */
export const shallowMergeUrlParams = (base, obj) => {
	const merged = { ...base };

	Object.entries(obj).forEach(([key, value]) => {
		if (!(key in base)) {
			merged[key] = value;
		} else if (!Array.isArray(base[key]) && value !== base[key]) {
			merged[key] = [base[key], value];
		} else if (Array.isArray(base[key]) && value !== base[key].at(-1)) {
			merged[key] = [...base[key], value];
		}
	});

	return merged;
};

/**
 * Extracts UTM parameters from the url.
 */
export const getUtmParameters = () => {
	const urlSearch = qs.parse(window.location.search, { ignoreQueryPrefix: true });
	return {
		utmCampaign: urlSearch.utm_campaign,
		utmContent: urlSearch.utm_content,
		utmMedium: urlSearch.utm_medium,
		utmSource: urlSearch.utm_source,
		utmTerm: urlSearch.utm_term,
	};
};
