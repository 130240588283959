import React from 'react';
import classNames from 'classnames';

import HexagonalCTA from '~globals/HexagonalCTA';

const hoverColors = {
	teal: 'hover:bg-teal-50',
	emerald: 'hover:bg-emerald-50',
};

const ProductCard = ({ title, subtitle, description, cta, hoverColor, icon, iconBefore }) => (
	<>
		{iconBefore && (
			<img
				src={`${icon}?fm=webp`}
				alt=""
				className={classNames('absolute w-4-col -top-2-col transform -rotate-12 left-1-col')}
			/>
		)}
		<div
			className={classNames(
				'rounded-2xl border p-1-col h-full bg-pureWhite relative',
				hoverColors[hoverColor],
			)}
		>
			<h4 className="type-h3 mb-2">{title}</h4>
			<p className="type-h5 mb-2">{subtitle}</p>
			<p className="type-body-2 font-serif">{description}</p>
			{cta && (
				<HexagonalCTA href={cta.href} className="mt-8" text={cta.label} newTab={cta.openInNewTab} />
			)}
		</div>
		{!iconBefore && (
			<img
				src={`${icon}?fm=webp`}
				alt=""
				className={classNames('absolute w-4-col -bottom-2-col right-1-col')}
			/>
		)}
	</>
);

export default ProductCard;
