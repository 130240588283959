import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import './styles.css';

const LogoWall = ({ backgroundColor, title, logos }) => {
	return (
		<section
			className={classNames({
				'bg-pureWhite': backgroundColor === 'white',
				'bg-coolGray-50': backgroundColor === 'gray-50',
				'bg-coolGray-300': backgroundColor === 'gray-300',
			})}
		>
			<div className="grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl gap-y-8 lg:gap-y-16">
				<div className="col-span-full grid grid-cols-11 lg:grid-cols-25 mx-auto w-container max-w-screen-xl">
					<p className="type-eyebrow opacity-60 mb-3 col-span-full text-center">{title}</p>
					<div className="logo-wall__icon col-span-9 lg:col-span-23 col-start-2 lg:col-start-2 flex flex-wrap items-center justify-around lg:justify-center gap-x-6 xs:gap-x-4 lg:gap-x-16 mb-8 gap-y-8">
						{logos.map((logo, index) => (
							<img
								alt={logo.description}
								className="h-20 xs:h-16 w-auto block"
								key={index}
								src={`${logo.file.url}?fm=webp`}
								width={logo.width}
								height={logo.height}
							/>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export const SectionLogoWallFragment = graphql`
	fragment SectionLogoWallFragment on ContentfulSectionLogoWall {
		title
		logos {
			file {
				url
				fileName
			}
			width
			height
			description
		}
		backgroundColor
	}
`;

export default LogoWall;
